// regular style toast
@import "ngx-toastr/toastr";

/* You can add global styles to this file, and also import other style files */
@import "./app/shared/hamburgers/hamburgers.scss";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "assets/style/colours";

.launcherNote {
  color: $goldtext !important;
  font-weight: bold !important;
}

.hyperlink {
  color: $goldtext;
  cursor: pointer;
  text-decoration: underline;
  transition: 300ms;

  &:hover {
    color: $btncolor;
  }
}

.downloadButton {
  gap: 1rem;

  svg {
    width: 1.5rem;
    fill: white;
  }
}

.logsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.error-border {
  border: 1px solid red;
  border-bottom: 1px solid red !important;
}

// Toastr stuff
.toast-success {
  background-color: $medium;
}

.shop-special {
  display: flex;
  justify-content: center;
  gap: 50px;
}

// Owl Stuff
.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.owl-dots button {
  margin: 0;
  position: relative;
}

.owl-theme .owl-dots .owl-dot span {
  transition: all 0.2s ease-in-out;
  z-index: 1;
  margin: -2px;
  height: 3px;
  width: 100px;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
}

.owl-theme .owl-dots .owl-dot.active span {
  z-index: 2;
  height: 5px;
  background: #fff;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 75px;
}

.issueHeading {
  p {
    color: var(--secondary_yellow);
    margin: 0;
  }
}

.customs_sliderHeadings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .customs_navigation {
    display: flex;
    flex-direction: row;
    gap: 10px;

    div {
      cursor: pointer;
    }
  }
}

.issueInput {
  input {
    padding: 0 2rem !important;
  }

  select {
    background: var(--input);
    font-family: "Poppins";
    font-weight: 600;
    font-size: 0.9rem;
    width: 100%;
    height: 100%;
    border: 0.05rem solid var(--input_border);
    color: var(--secondary_yellow);
    transition: 0.3s;
    border-radius: 0.3rem;
    padding: 0 2rem;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;

    &:focus,
    &:focus-visible {
      border-color: var(--main_accent);
      outline: none;
      outline-width: 0;
    }

    option {
      color: #fff;
    }
  }

  textarea {
    background: var(--input);
    font-family: "Poppins";
    font-weight: 600;
    font-size: 0.9rem;
    width: 100%;
    height: 100%;
    border: 0.05rem solid var(--input_border);
    color: var(--secondary_yellow);
    transition: 0.3s;
    border-radius: 0.3rem;
    padding: 0.5rem 2rem;
    resize: none;
  }

  ckeditor {
    width: 100%;
  }

  .ck-editor__editable_inline {
    min-height: 15rem;
    height: 15rem;
    border: 0.05rem solid var(--input_border) !important;
    color: var(--secondary_yellow);
    background: var(--input) !important;
    transition: 0.3s;
    border-radius: 0.3rem;
    padding: 0.5rem 2rem;
  }

  &:has(ckeditor) {
    height: 15rem;
  }
}

.issueForm {
  max-width: 60% !important;

  h1 {
    font-weight: 600 !important;
    color: var(--main_accent) !important;
  }

  md-editor {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  // .main_bg {
  //   &::after {
  //     background: url(./assets/img/bg/main/dragon_effect-on.webp) top no-repeat !important;
  //     background-size: auto !important;
  //   }
  // }

  .customs_navigation {
    display: none;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 20px;
  }

  .news_read-content_topic-read {
    text-align: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

// Custom SCSS
.disabled {
  background-color: #393012 !important;
  cursor: progress !important;
}

.news_mainSlider .swiper-slide,
.news_mainSlider .swiper-slide > div {
  min-height: 275px;
  max-height: 275px;
  opacity: 1;

  h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.loader {
  width: 100%;
  height: 20px;
  background: #171717;
  transition: 300ms;
  animation: loader 2s ease-in infinite;
}

.space {
  margin-bottom: 30px;
}

@keyframes loader {
  0% {
    background: #171717;
  }

  50% {
    background: #9a9a9a61;
  }

  100% {
    background: #171717;
  }
}

// Supplied SCSS

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Strong Sword";
  src: url("./assets/vendor/font/StrongSword-Bold.eot");
  src:
    url("./assets/vendor/font/StrongSword-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/vendor/font/StrongSword-Bold.woff2") format("woff2"),
    url("./assets/vendor/font/StrongSword-Bold.woff") format("woff"),
    url("./assets/vendor/font/StrongSword-Bold.ttf") format("truetype"),
    url("./assets/vendor/font/StrongSword-Bold.svg#StrongSword-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Strong Sword";
  src: url("./assets/vendor/font/StrongSword-Regular.eot");
  src:
    url("./assets/vendor/font/StrongSword-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/vendor/font/StrongSword-Regular.woff2") format("woff2"),
    url("./assets/vendor/font/StrongSword-Regular.woff") format("woff"),
    url("./assets/vendor/font/StrongSword-Regular.ttf") format("truetype"),
    url("./assets/vendor/font/StrongSword-Regular.svg#StrongSword-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --global_nav: linear-gradient(-103deg, #191409, #171717);
  --global_body: #13131f;

  --global_red: #ff1919;
  --global_red-warning: #ed7070;
  --global_red-soft: #402929;
  --global_red-dark: #251717;

  --global_green-warning: #70ed70;
  --global_green-soft: #304029;
  --global_green-dark: #1c2617;

  --main_accent: #17afcb;

  --primary_yellow: #ffd95a;
  --secondary_yellow: #a6a6a5;

  --primary_blue: #799ee9;
  --secondary_blue: #797e9c;
  --button_main: #067db9;

  --form: rgba(20, 22, 36, 0.85);
  --input: rgba(41, 43, 64, 0.7);
  --input_border: #383b50;

  --dashboard_blue-soft: #292b40;
  --dashboard_blue-darker: rgba(28, 29, 48, 0.9);
  --dashboard_blue-darker_dropdown: rgba(21, 21, 35, 0.9);

  --global_blue-soft: #292b40;
  --global_blue-darker: rgba(28, 29, 48, 1);
  --global_blue-darker_05: rgba(28, 29, 48, 0.5);

  --shop_gradient-special: linear-gradient(160deg, #503c28, #372817);
  --shop_gradient: linear-gradient(160deg, #2b2c3f, #1b1c29);

  --custom_body: #13131f;
}

body {
  margin: 0;
  background-color: var(--global_body);
  font-family: "Poppins";
}

.custom_body {
  background-color: var(--custom_body) !important;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-family: "Strong Sword";
}

// Page Bgs

.page_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.page_bg-customs {
  background: url(./assets/img/customs/bg/1.webp) top no-repeat;
  height: 55rem;
}

.page_bg-orc_1 {
  background: url(./assets/img/bg/page/sign_up.webp) top no-repeat;
  height: 80rem;
}

.page_bg-orc_2 {
  background: url(./assets/img/bg/page/dashboard.webp) top no-repeat;
  height: 60rem;
}

.page_bg-map_1 {
  background: url(./assets/img/bg/page/form_only-bg.webp) top no-repeat;
  height: 60rem;
}

.page_bg-map_2 {
  background: url(./assets/img/bg/page/shop.webp) top no-repeat;
  height: 60rem;
}

.page_bg-map_3 {
  background: url(./assets/img/bg/page/changelog.webp) top no-repeat;
  height: 60rem;
}

.page_bg-map_3_single_issue {
  background: url(./assets/img/bg/page/changelog.webp) bottom no-repeat;
  height: 45rem;
}

.page_bg-map_4 {
  background: url(./assets/img/bg/page/news.webp) top no-repeat;
  height: 60rem;
}

.page_bg-map_5 {
  background: url(./assets/img/bg/page/timeline.webp) top no-repeat;
  height: 50rem;
}

.news_read-bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 35rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150%;
    background: linear-gradient(rgba(0, 0, 0, 0.4), var(--global_body));
    z-index: 1;
  }

  img {
    width: 100%;
    opacity: 1;
    height: 100%;
    object-fit: cover;
  }
}

// Dusk Coin

.dusk_coin {
  position: relative;
  z-index: 1;
  padding: 10rem 0 5rem 0;

  .global_page-navIndicator {
    margin-bottom: 2rem;
  }

  &-title {
    margin-bottom: 2.2rem;

    h1 {
      font-size: 2.5rem;
      margin: 0;
    }
  }

  .main_container {
    max-width: 62.6rem;
  }

  &-summary {
    p {
      margin: 0;
      color: var(--secondary_yellow);
      font-weight: 500;
    }

    ul {
      margin: 2rem 0 0 0;
      padding: 0;
      background-color: var(--global_blue-darker);
      border-radius: 0.4rem;
      border: 0.05rem solid var(--global_blue-soft);
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 1rem 1rem 2rem;
      box-sizing: border-box;

      li {
        width: 20%;
        list-style: none;

        p {
          font-size: 0.9rem;
        }

        span {
          color: var(--main_accent);
          font-weight: 500;
        }

        #methodResult {
          color: white;
        }

        button {
          border: none;
          background-color: var(--button_main);
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-radius: 0.2rem;
          height: 100%;
          font-size: 1rem;
          letter-spacing: 0.05rem;
          color: white;
          text-transform: uppercase;
          font-weight: bold;
          overflow: hidden;
          transition: 0.3s;
          position: relative;
          cursor: pointer;
          font-family: "Strong Sword";

          &:hover {
            box-shadow: inset 0 0 1rem var(--main_accent);

            &::before {
              left: -70%;
              opacity: 0.6;
            }
          }

          &::before {
            content: "";
            position: absolute;
            width: 120%;
            height: 100%;
            background-color: var(--main_accent);
            left: -100%;
            top: 0;
            filter: blur(4rem);
            opacity: 0;
            transition: 0.5s;
          }
        }
      }
    }
  }

  &-bonus {
    width: 100%;
    background: linear-gradient(120deg, var(--main_accent), white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.4rem;
    height: 3.6rem;
    padding: 0 1rem 0 2rem;
    box-sizing: border-box;

    h1 {
      font-size: 1.2rem;
      color: var(--global_blue-darker);
    }

    span {
      position: relative;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      background-color: var(--global_blue-darker);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      cursor: pointer;

      &:hover {
        color: var(--main_accent);

        p {
          opacity: 1;
          bottom: 80%;
        }
      }

      p {
        position: absolute;
        width: 18rem;
        text-align: center;
        right: 0;
        font-size: 0.9rem;
        background-color: var(--global_blue-darker);
        border-radius: 0.4rem;
        padding: 1rem;
        bottom: 60%;
        opacity: 0;
        pointer-events: none;
        transition: 0.3s;
        border: 0.1rem solid var(--global_blue-soft);
        color: var(--secondary_yellow);

        strong {
          color: var(--main_accent);
        }
      }
    }
  }

  &-title {
    h1 {
      color: white;
    }
  }

  &-list {
    display: flex;
    gap: 2.8rem;
    flex-wrap: wrap;
    margin-top: 2rem;

    label {
      border-radius: 0.4rem;
      width: 19rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      content: "";
      position: relative;
      overflow: hidden;

      input {
        appearance: none;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 0.4rem;
        margin: 0;
        outline: none;
        top: 0;
        left: 0;
        border: 0.15rem solid var(--global_blue-soft);
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          left: -1.6rem;
          top: -1.6rem;
          transform: rotate(45deg);
          width: 3.2rem;
          height: 3.2rem;
          opacity: 0;
          background-color: var(--main_accent);
        }

        &::after {
          content: "✓";
          position: absolute;
          left: 0.45rem;
          font-weight: bold;
          top: 0.2rem;
          color: var(--global_blue-darker);
          opacity: 0;
          transform: rotate(10deg);
          font-size: 0.7rem;
        }

        &:checked {
          border-color: var(--main_accent);

          &::before {
            opacity: 1;
          }

          &::after {
            opacity: 1;
          }
        }

        &:checked ~ div:nth-child(3) {
          background-color: var(--global_blue-darker);
        }

        &:checked ~ div:nth-child(2) {
          background-color: var(--global_blue-soft);
        }

        &:hover ~ div:nth-child(3) {
          background-color: var(--global_blue-darker);
        }

        &:hover ~ div:nth-child(2) {
          background-color: var(--global_blue-soft);
        }
      }

      img {
        max-width: 7rem;
      }

      div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 8rem;
        background-color: var(--global_blue-darker);
        box-sizing: border-box;
        width: 100%;
        transition: 0.3s;
      }

      div:nth-child(3) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: var(--global_blue-soft);
        padding: 0.9rem 1.5rem;
        box-sizing: border-box;
        transition: 0.3s;

        span {
          display: flex;
          align-items: center;
          color: var(--main_accent);
          font-weight: 600;
          gap: 0.4rem;

          svg {
            width: 1.3rem;
            height: 1.2rem;
          }

          span:nth-child(1) {
            width: 1rem;
            height: 1rem;
            background-color: var(--main_accent);
            color: var(--global_blue-darker);
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.7rem;
            font-weight: bold;
          }

          span:nth-child(2) {
            font-size: 0.8rem;
            font-weight: 700;
            color: var(--global_blue-darker);
            padding: 0.08rem 0.8rem;
            border-radius: 2rem;
            margin-left: 0.2rem;
            background-color: var(--main_accent);
          }
        }

        span:nth-child(2) {
          color: white;
        }
      }
    }
  }

  form {
    margin-bottom: 1rem;
    border-bottom: 0.05rem solid rgba(255, 255, 255, 0.05);

    p {
      color: var(--secondary_yellow);
      font-weight: 500;
      margin: 0;
    }
  }

  #duskPayment {
    label {
      height: 9.2rem;

      img {
        max-width: 8rem;
        position: relative;
        z-index: 1;
        pointer-events: none;
      }

      input {
        background-color: var(--global_blue-darker);
        transition: 0.3s;

        &:hover {
          background-color: var(--global_blue-soft);
        }

        &:checked {
          background-color: var(--global_blue-soft);
        }
      }
    }
  }
}

// Customs

.customs {
  padding: 0 0;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .custom_noBorder {
    .customs_content-title {
      &::before {
        display: none;
      }
    }
  }

  .mythic_custom {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 65rem;
      top: 4rem;
      left: 0;
      background: url(./assets/img/customs/bg/4.webp) top no-repeat;
    }
  }

  .hardcore_custom {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 65rem;
      top: -2rem;
      left: 0;
      background: url(./assets/img/customs/bg/7.webp) top no-repeat;
    }
  }

  .pvp_custom {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 65rem;
      top: -5rem;
      left: 0;
      background: url(./assets/img/customs/bg/8.webp) top no-repeat;
    }
  }

  .lairs_custom {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 65rem;
      top: 4rem;
      left: 0;
      background: url(./assets/img/customs/bg/6.webp) top no-repeat;
    }
  }

  &_content-withList {
    position: relative;
    padding-bottom: 8rem;

    .customs_content-title {
      padding: 10rem 0 0 0;
      margin-bottom: 22rem;
    }

    ul {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 6rem;
      margin: 0 auto;
      padding: 0;

      li {
        width: 21.8rem;
        list-style: none;

        div {
          text-align: center;

          h1 {
            color: white;
            font-size: 1.7rem;
          }

          p {
            color: var(--secondary_yellow);
            width: 90%;
            margin: 0 auto;
            font-weight: 500;
          }
        }

        div:nth-child(1) {
          width: 100%;
          height: 14.7rem;
          margin-bottom: 2.5rem;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            border: 1.4rem solid transparent;
            border-image: url(./assets/img/frames/global_frame.png);
            border-image-slice: 35 35 fill;
            left: -0.2rem;
            right: -0.2rem;
            bottom: -0.2rem;
            top: -0.2rem;
            pointer-events: none;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 2.4rem;
          }
        }
      }
    }
  }

  &_content-withTab {
    .customs_content-title {
      padding: 10rem 0 5rem 0;
    }

    .combination_alliance {
      background: none;

      .customs_content-withTab_content-table {
        ul {
          .combination_th {
            background-color: rgba(27, 27, 77, 0.718);
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50rem;
        background: url(./assets/img/customs/bg/3_alliance.webp) top no-repeat;
      }
    }

    .combination_horder {
      background: none;

      .customs_content-withTab_content-table {
        ul {
          .combination_th {
            background-color: rgba(77, 27, 27, 0.718);
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50rem;
        background: url(./assets/img/customs/bg/3_horder.webp) top no-repeat;
      }
    }

    .raid_custom-scarlet {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 53rem;
        background: url(./assets/img/customs/bg/5_scarlet.webp) top no-repeat;
      }
    }

    .raid_custom-gnoll {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 53rem;
        background: url(./assets/img/customs/bg/5_scarlet.webp) top no-repeat;
      }
    }

    .race_custom {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 53rem;
        background: url(./assets/img/customs/bg/2.webp) top no-repeat;
      }
    }

    &_content {
      min-height: 50rem;
      margin: 0;
      position: relative;
      padding-top: 4rem;
      box-sizing: border-box;

      &-table {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start !important;
        padding: 4.5rem 0;

        h1 {
          color: white;
          font-size: 2.5rem;
          width: 50%;
          padding: 0;
          margin-bottom: 5rem;
          text-align: left;
        }

        ul {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          width: 100%;

          .combination_th {
            position: relative;
            border-radius: 1.5rem;

            &::after {
              content: "";
              position: absolute;
              border: 0.9rem solid transparent;
              border-image: url(./assets/img/frames/global_frame.png);
              border-image-slice: 35 35 fill;
              left: 0rem;
              right: 0rem;
              bottom: -0.1rem;
              top: 0rem;
              pointer-events: none;
            }

            p {
              color: white;
            }
          }

          .combination_td {
            p {
              color: var(--secondary_yellow);
            }
          }

          li:not(:last-child) {
            border-bottom: 0.05rem solid rgba(255, 255, 255, 0.05);
          }

          li {
            display: flex;
            padding: 0.4rem 2rem 0.4rem 2.6rem;
            justify-content: space-between;
            width: 100%;
            align-items: center;

            p {
              width: 14.5rem;
              font-weight: 600;
              font-size: 1.15rem;
            }

            div {
              display: flex;
              position: relative;
              align-items: center;
              justify-content: center;
              height: 2.2rem;
              width: 2.2rem !important;
              width: 0;

              &:hover {
                span {
                  opacity: 1;
                }

                img {
                  border-color: white;
                }
              }

              svg {
                path {
                  fill: greenyellow;
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0.3rem;
                border: 0.05rem solid var(--main_accent);
                cursor: pointer;
                transition: 0.2s;
              }

              span {
                position: absolute;
                bottom: 150%;
                color: var(--secondary_yellow);
                width: 10rem;
                background-color: rgb(23, 23, 23);
                padding: 0.5rem;
                opacity: 0;
                z-index: 2;
                pointer-events: none;
                border-radius: 0.4rem;
                display: flex;
                justify-content: center;

                &::before {
                  content: "";
                  background-color: rgb(23, 23, 23);
                  width: 0.5rem;
                  height: 0.5rem;
                  transform: rotate(45deg);
                  position: absolute;
                  bottom: -0.25rem;
                }
              }
            }
          }
        }
      }

      .newRace_img {
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        margin: 0 auto;

        display: flex;
        justify-content: center;

        img {
          width: 120rem;
        }
      }

      .main_container {
        box-sizing: border-box;
        min-height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;

        div {
          width: 40%;
          text-align: center;
          box-sizing: border-box;

          h1 {
            margin: 0;
            color: white;
            font-family: "Poppins";
            font-size: 3.7rem;
            line-height: 4rem;
            font-weight: 600;
            letter-spacing: 0.05rem;
          }

          a {
            display: flex;
            margin: 0 auto;
            align-items: center;
            justify-content: center;
            width: 6.2rem;
            height: 6.2rem;
            font-size: 1.9rem;
            position: relative;
            box-sizing: border-box;

            &:hover {
              svg {
                path {
                  fill: white;
                }
              }

              &::before {
                opacity: 0.3;
              }
            }

            svg {
              position: relative;
              z-index: 2;
              left: 0.2rem;

              path {
                transition: 0.3s;
                fill: var(--main_accent);
              }
            }

            &::before {
              content: "";
              transition: 0.3s;
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: var(--main_accent);
              opacity: 0.15;

              border-radius: 100%;
            }

            &::after {
              content: "";
              transition: 0.3s;
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: var(--main_accent);
              opacity: 0;
              border-radius: 100%;
              animation: custom_play-animation 2s infinite;
            }

            @keyframes custom_play-animation {
              0% {
                opacity: 0;
                transform: scale(0);
              }

              50% {
                opacity: 0.1;
              }

              100% {
                opacity: 0;
                transform: scale(1.5);
              }
            }
          }

          p {
            color: var(--secondary_yellow);
            font-size: 1.35rem;
            letter-spacing: 0.01rem;
            font-weight: 500;
            margin-bottom: 2.5rem;
          }
        }
      }
    }

    &_tabs {
      border-top: 0.05rem solid rgba(255, 255, 255, 0.1);
      border-bottom: 0.05rem solid rgba(255, 255, 255, 0.05);
      margin: 0;

      .main_container {
        display: flex;
        gap: 8rem;
        justify-content: center;
      }

      .tba {
        pointer-events: none;
        opacity: 0.4;
      }

      span {
        margin: 0;
        cursor: pointer;
        display: inline-flex;
        height: 6.2rem;
        align-items: center;
        justify-content: center;
        color: var(--secondary_yellow);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.15rem;
        letter-spacing: 0.05rem;
        border-bottom: 0.1rem solid transparent;
        transition: 0.3s;

        &:hover {
          color: var(--main_accent);
        }

        &.active {
          color: var(--main_accent);
          border-color: var(--main_accent);
        }
      }
    }
  }

  &_content {
    &-title {
      text-align: center;
      position: relative;

      span {
        width: 3.4rem;
        font-size: 1.6rem;
        height: 3.4rem;
        font-family: "Strong Sword";
        font-weight: bold;
        align-items: center;
        justify-content: center;
        color: var(--secondary_yellow);
        display: inline-flex;
        margin-bottom: 2rem;
        position: relative;
        background: url(./assets/img/customs/number_icon.png) top / cover;

        &::before {
          content: "";
          border-radius: 100%;
          width: 100%;
          height: 100%;
          background-color: var(--main_accent);
          position: absolute;
          filter: blur(3rem);
          opacity: 0.5;
        }
      }

      h1 {
        color: var(--main_accent);
        font-size: 3.4rem;
        margin: 0;
      }

      p {
        color: var(--secondary_yellow);
      }

      &::before {
        content: "";
        background: url(./assets/img/frames/frame_line.png) top / cover
          no-repeat;
        width: 100%;
        height: 1.29rem;
        opacity: 0.2;
        top: 0;
        left: 0;
        position: absolute;
      }
    }
  }

  &_heading {
    padding: 11rem 0 6.8rem 0;

    .main_container:nth-child(2) {
      p {
        color: white;
        font-weight: 500;
        margin: 0;
        margin-bottom: 3.3rem;
        font-size: 1.15rem;
      }
    }

    .main_container:nth-child(1) {
      margin-bottom: 5.8rem;

      h1 {
        color: var(--main_accent);
        font-size: 3.6rem;
        margin: 0;
        width: 60%;
      }

      p {
        color: var(--secondary_yellow);
        font-weight: 500;
        font-size: 1.15rem;
        width: 60%;
      }
    }

    &-slider {
      position: relative;

      .swiper-slide {
        position: relative;
        height: 10rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
        gap: 1rem;
        padding: 1.7rem;
        text-align: center;
        font-weight: 600;
        font-size: 1.12rem;
        box-sizing: border-box;
        justify-content: center;
        border-radius: 2rem;
        background-color: rgba(46, 46, 46, 0.3);
        color: var(--secondary_yellow);
        transition: 0.3s;

        &:hover {
          color: var(--main_accent);

          svg {
            path {
              fill: var(--secondary_yellow);
            }
          }
        }

        svg {
          path {
            transition: 0.3s;
            fill: var(--main_accent);
          }
        }

        &::after {
          content: "";
          position: absolute;
          border: 1rem solid transparent;
          border-image: url(./assets/img/frames/global_frame.png);
          border-image-slice: 35 35 fill;
          left: -0.2rem;
          right: -0.2rem;
          bottom: -0.3rem;
          top: -0.2rem;
          pointer-events: none;
        }
      }

      &_prev {
        top: -4rem;
        left: 91%;
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 100%;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.15rem solid var(--secondary_yellow);
        box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
        transition: 0.3s;

        &:hover {
          border-color: var(--main_accent);
        }

        svg {
          object-fit: none !important;
          font-size: 1rem;
          width: auto;
          height: auto;

          path {
            fill: var(--main_accent);
          }
        }

        &::after {
          display: none;
        }
      }

      &_next {
        top: -4rem;
        right: 0;
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 100%;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.15rem solid var(--secondary_yellow);
        box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
        transition: 0.3s;

        &:hover {
          border-color: var(--main_accent);
        }

        svg {
          object-fit: none !important;
          font-size: 1rem;
          width: auto;
          height: auto;

          path {
            fill: var(--main_accent);
          }
        }

        &::after {
          display: none;
        }
      }
    }
  }
}

// News Read

.news_read {
  padding: 8rem 0 6rem 0;
  position: relative;
  z-index: 1;

  .global_page-navIndicator {
    margin-bottom: 3rem;
  }

  &-content {
    display: flex;
    justify-content: space-between;

    &_menu {
      width: 27%;

      border-radius: 0.4rem;
      height: fit-content;
      position: sticky;
      top: 8rem;
      box-sizing: border-box;

      h1 {
        margin: 0;
        padding: 0 0 1rem 0;
        font-size: 1.1rem;
        font-family: "Poppins";
        font-weight: 600;
        color: var(--secondary_yellow);
      }

      ul {
        padding: 0;
        margin: 0;

        background-color: var(--global_blue-darker);
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        max-height: 70vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0;
        }

        li:not(:last-child) {
          border-bottom: 0.05rem solid rgba(255, 255, 255, 0.047);
        }

        li {
          list-style: none;
          display: flex;
          height: 100%;

          a {
            text-decoration: none;
            font-weight: 500;
            display: flex;
            align-items: flex-start;
            gap: 1.5rem;
            padding: 1.7rem;
            transition: 0.3s;

            &:hover {
              background-color: rgba(255, 255, 255, 0.03);
            }

            div:nth-child(1) {
              width: 6rem;
              height: 100%;
              display: flex;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                border: 0.6rem solid transparent;
                border-image: url(./assets/img/frames/global_frame.png);
                border-image-slice: 35 35 fill;
                left: -0.2rem;
                right: -0.2rem;
                bottom: -0.3rem;
                top: -0.2rem;
                pointer-events: none;
              }

              img {
                width: 100%;
                height: 100%;
                border-radius: 0.5rem;
                object-fit: cover;
              }
            }

            div:nth-child(2) {
              width: 70%;
            }

            p {
              margin: 0;
              color: var(--secondary_yellow);
              font-size: 0.8rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            span {
              color: var(--secondary_blue);
              opacity: 0.4;
              font-size: 0.7rem;
            }
          }
        }
      }
    }

    &_topic {
      width: 68%;
    }

    &_topic-read {
      width: 100%;

      &_ending {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 3.5rem;
        padding-top: 2rem;
        border-top: 0.1rem solid rgba(255, 255, 255, 0.062);
        align-items: center;
        gap: 2rem;

        div {
          display: flex;
          align-items: center;
          gap: 1rem;
          flex-wrap: wrap;

          p {
            margin: 0 !important;
            color: var(--secondary_yellow);
            font-size: 0.8rem;
            font-weight: 700;
            padding-right: 0.6rem;
            text-transform: uppercase;
          }

          span {
            background-color: rgba(255, 255, 255, 0.1);
            color: var(--secondary_yellow);
            font-size: 0.8rem;
            padding: 0.4rem 1rem;
            font-weight: 500;
            border-radius: 0.2rem;
          }
        }

        a {
          text-decoration: none;
          background-color: rgba(255, 255, 255, 0.058);
          color: var(--secondary_yellow) !important;
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 0.9rem;
          font-weight: 500;
          padding: 0.8rem 1.5rem;
          border-radius: 0.4rem;
          height: fit-content;

          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }

          svg {
            path {
              fill: var(--secondary_yellow);
            }
          }
        }
      }

      img {
        max-width: 100%;
        border-radius: 0.4rem;
      }

      .red_quote {
        border: 0.05rem solid var(--global_red-warning);
        border-left: 0.2rem solid var(--global_red-warning);
        color: var(--global_red-warning);
        padding: 1.2rem 2rem;
        font-size: 0.9rem;
        font-weight: 500;
        border-radius: 0rem 0.4rem 0.4rem 0;
      }

      a {
        color: var(--main_accent);
        transition: 0.3s;

        &:hover {
          color: white;
        }
      }

      blockquote {
        margin: 1rem 0;
      }

      ul {
        padding: 1.5rem 4rem;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 0.95rem;

        ul {
          padding: 1.5rem 2rem;
          padding-right: 0;
        }

        li {
          color: var(--secondary_yellow);
          font-weight: 500;

          img {
            max-width: 30rem;
          }
        }
      }

      img {
        margin: 1rem 0;
      }

      p {
        color: var(--secondary_yellow);
        font-weight: 500;
      }

      h1 {
        color: var(--main_accent);
        font-family: "Poppins";
        font-weight: 600;
        position: relative;
        padding-left: 3rem;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0.4rem;
          opacity: 0.5;
          width: 2.1rem;
          height: 2.1rem;
          background: url(./assets/img/logo/logo.png) top / cover;
        }
      }

      h1:nth-child(2) {
        margin-top: 1rem;
      }

      h1:nth-child(1) {
        margin-top: 1rem;
      }

      h1 {
        margin-top: 5rem;
      }

      h2 {
        color: white;
        font-weight: 500;
      }

      .blue_quote {
        border: 0.05rem solid var(--primary_blue);
        border-left: 0.2rem solid var(--primary_blue);
        color: var(--primary_blue);
        padding: 1.2rem 2rem;
        font-size: 0.9rem;
        font-weight: 500;
        border-radius: 0rem 0.4rem 0.4rem 0;
      }

      table {
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        border: 0.1rem solid var(--global_blue-darker);

        img {
          max-width: 15rem;
        }

        th {
          background-color: var(--global_blue-soft);
          color: white;
          font-weight: 500;
          padding: 1.2rem;
        }

        td {
          background-color: var(--global_blue-darker_05);
          color: var(--secondary_yellow);
          font-weight: 500;
          padding: 1.2rem;

          flex-direction: column;
        }
      }
    }
  }

  &-title {
    margin-bottom: 2.3rem;
    padding-bottom: 3rem;
    width: 100%;
    border-bottom: 0.15rem solid rgba(255, 255, 255, 0.05);

    h1 {
      font-family: "Poppins";
      font-size: 2.8rem;
      line-height: 3.5rem;
      color: white;
      margin: 0;
      margin-bottom: 2.5rem;
      font-weight: 600;
      padding-left: 0;

      &::before {
        display: none;
      }
    }

    div {
      display: flex;
      gap: 2rem;
      font-weight: 500;
      align-items: center;
      flex-wrap: wrap;
    }

    &_tag {
      padding-right: 2rem;
      border-right: 0.1rem solid rgba(255, 255, 255, 0.129);
    }

    &_date {
      color: var(--secondary_yellow);
    }

    &_tagList {
      display: flex;
      gap: 1rem;

      span {
        background-color: rgba(255, 255, 255, 0.1);
        color: var(--secondary_yellow);
        font-size: 0.8rem;
        padding: 0.3rem 1rem;
        border-radius: 0.2rem;
      }
    }
  }
}

// New tags

.new_tagUpdate {
  color: #b7f58e !important;
}

.new_tagMaintenance {
  color: red !important;
}

.new_tagAnnouncement {
  color: orange !important;
}

.new_tagEvent {
  color: red !important;
}

.new_tagDev {
  color: aqua !important;
}

.new_tagStore {
  color: #d07ff5 !important;
}

// News

.news {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 5rem 0;

  .global_page-navIndicator {
    margin-bottom: 1rem;
  }

  &_title {
    h1 {
      color: var(--main_accent);
      font-size: 2.8rem;
      margin: 0;
    }
  }

  &_mainNews {
    padding: 3rem 0 9rem 0;
    position: relative;
    box-sizing: border-box;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
    }
  }

  &_mainSlider {
    position: relative;
    overflow: visible !important;
    z-index: 1;

    .swiper-slide {
      position: relative;
      height: 23.7rem;
      border-radius: 2.4rem;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.378);
      text-decoration: none;
      opacity: 0.5;
      transform: translateX(4.7rem);
      transition: 0.3s;

      &-active {
        opacity: 1;

        transform: translateX(0rem);
      }

      &-prev {
        transform: translateX(-4.7rem);
        overflow: 0.5;
      }

      &-next {
        opacity: 1;

        transform: translateX(0rem);
      }

      &:hover {
        div:nth-child(1) {
          img {
            transform: scale(1.1);
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        border: 1.7rem solid transparent;
        border-image: url(./assets/img/frames/global_frame.png);
        border-image-slice: 35 35 fill;
        left: -0.2rem;
        right: -0.2rem;
        bottom: -0.3rem;
        top: -0.2rem;
        pointer-events: none;
      }

      div {
        position: relative;
      }

      div:nth-child(1) {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        border-radius: 2.4rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.3s;
        }
      }

      div:nth-child(2) {
        width: 100%;
        height: 100%;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: 2.4rem;
        padding: 3rem 3.5rem;
        background: linear-gradient(rgba(0, 0, 0, 0.001), black);

        h1 {
          font-size: 1.3rem;
          font-weight: 500;
          color: white;
          font-family: "Poppins";

          span {
            font-size: 0.95rem;
            top: -0.15rem;
            position: relative;
            margin-right: 0.3rem;
            opacity: 1;
            color: var(--main_accent);
          }
        }

        span {
          color: var(--secondary_yellow);
          font-weight: 500;
          opacity: 0.5;
          font-size: 0.9rem;
        }
      }
    }

    &-pagination {
      bottom: -6rem !important;
      right: 0 !important;
      display: flex;
      justify-content: center;
      height: 2rem;
      gap: 1rem;

      .swiper-pagination-bullet {
        width: 4.8rem;
        height: 0.2rem;
        border-radius: 1rem;
        transition: 0.3s;
        background-color: white;

        &-active {
          background-color: var(--main_accent);
        }
      }
    }

    &-prev {
      left: -6rem;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 100%;
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.15rem solid var(--global_blue-soft);
      box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.549);
      transition: 0.3s;

      &:hover {
        border-color: var(--secondary_yellow);
      }

      svg {
        object-fit: none !important;
        font-size: 1.3rem;
        width: auto;
        height: auto;

        path {
          fill: var(--main_accent);
        }
      }

      &::after {
        display: none;
      }
    }

    &-next {
      right: -6rem;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 100%;
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.15rem solid var(--global_blue-soft);
      box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.549);
      transition: 0.3s;

      &:hover {
        border-color: var(--secondary_yellow);
      }

      svg {
        object-fit: none !important;
        font-size: 1.3rem;
        width: auto;
        height: auto;

        path {
          fill: var(--main_accent);
        }
      }

      &::after {
        display: none;
      }
    }
  }

  &_list {
    &-news {
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        width: 100%;

        li {
          list-style: none;
          width: 100%;

          a {
            display: flex;
            gap: 3rem;
            width: 100%;
            padding: 1.8rem;
            background-color: var(--global_blue-darker_05);
            border-radius: 0.4rem;
            border: 0.05rem solid var(--global_blue-soft);
            text-decoration: none;
            box-sizing: border-box;
            transition: 0.3s;

            &:hover {
              background-color: var(--global_blue-darker);
            }

            div:nth-child(2) {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 1.5rem 0;
              width: 70%;

              span:nth-child(2) {
                background-color: rgb(15, 14, 23);
                border-radius: 0.3rem;
                color: var(--secondary_yellow);
                padding: 0.6rem 1.2rem;
                font-size: 0.8rem;
                box-sizing: border-box;
                opacity: 0.4;
                width: fit-content;
                font-weight: 500;
                letter-spacing: 0.05rem;
              }

              span:nth-child(1) {
                h1 {
                  margin: 0;
                  font-size: 1.4rem;
                  font-family: "Poppins";
                  font-weight: 500;
                  color: white;
                  margin-bottom: 0.5rem;
                  letter-spacing: 0.05rem;

                  span {
                    font-size: 0.95rem;
                    position: relative;
                    top: -0.15rem;
                    margin-right: 1rem;
                    color: yellow;
                  }
                }

                p {
                  margin: 0;
                  color: var(--secondary_yellow);
                  font-weight: 500;
                  font-size: 0.95rem;
                  letter-spacing: 0.05rem;
                }
              }
            }

            div:nth-child(1) {
              width: 16.9rem;
              height: 12.4rem;
              border-radius: 2.4rem;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                border: 1.4rem solid transparent;
                border-image: url(./assets/img/frames/global_frame.png);
                border-image-slice: 35 35 fill;
                left: -0.2rem;
                right: -0.2rem;
                bottom: -0.3rem;
                top: -0.2rem;
                pointer-events: none;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 2.4rem;
              }
            }
          }
        }
      }
    }
  }

  &_menu {
    border-top: 0.05rem solid rgba(255, 255, 255, 0.1);
    border-bottom: 0.05rem solid rgba(255, 255, 255, 0.05);
    padding: 1.6rem 0;
    margin-bottom: 4rem;

    .main_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    div {
      display: flex;
      align-items: center;
      gap: 2rem;
      flex-wrap: wrap;

      p {
        margin: 0;
        color: var(--secondary_yellow);
      }

      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 0;
        margin: 0;

        li {
          list-style: none;

          a {
            text-decoration: none;
            font-family: "Poppins";
            background-color: transparent;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0.7rem 2.2rem;
            border: none;
            color: var(--secondary_yellow);
            border-radius: 0.2rem;
            font-size: 0.9rem;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              color: var(--main_accent);
            }

            &.active {
              color: var(--main_accent);
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }
  }
}

// Changelog

.changelog {
  position: relative;
  z-index: 1;
  padding: 6rem 0;

  .global_page-navIndicator {
    margin-bottom: 1rem;
  }

  &_logs {
    ul {
      padding: 0;
      margin: 0;
      width: 100%;
      padding-left: 2.5rem;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      &.active {
        .changelog_logs-list {
          display: flex;
        }

        .changelog_logs-date {
          border-color: var(--main_accent);
          color: white;

          &::before {
            border-left-color: var(--main_accent);
            border-bottom-color: var(--main_accent);
          }

          &::after {
            opacity: 1;
          }

          svg {
            transform: rotate(90deg);
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        border-radius: 1rem;
        width: 0.3rem;
        background-color: var(--global_blue-soft);
        height: 100%;
      }

      li {
        width: 100%;
        list-style: none;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 1rem;
          left: -3.05rem;
          top: 1.45rem;
          height: 1rem;
          border-radius: 100%;
          border: 0.2rem solid var(--global_blue-soft);
          background-color: var(--global_body);
        }
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      padding: 2.5rem 3rem 0 3rem;
      display: none;

      h1 {
        margin: 0;
        padding-bottom: 1rem;
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.05);
        font-size: 1.1rem;
        font-weight: 500;
        font-family: "Poppins";
        color: white;
        margin-bottom: 1.8rem;
      }

      &_content {
        display: flex;
        margin-bottom: 1.5rem;
        flex-direction: column;

        div {
          display: flex;
          align-items: flex-start;
          gap: 1.2rem;
          margin-bottom: 1.3rem;

          span {
            color: white;
            width: 5.5rem;
            height: 1.85rem;
            border-radius: 0.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            font-weight: 500;
            position: relative;
            top: 0rem;
          }

          .changelog_New {
            background-color: rgb(207, 16, 16);
          }

          .changelog_Change {
            background-color: rgb(59, 59, 205);
          }

          p {
            width: 90%;
            margin: 0;
            color: var(--secondary_yellow);
            font-weight: 500;
            padding-top: 0.2rem;
          }
        }
      }
    }

    &-date {
      width: 100%;
      padding: 1.3rem 2rem;
      color: var(--secondary_yellow);
      background-color: var(--global_blue-darker);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0.3rem;
      border: 0.05rem solid var(--global_blue-soft);
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      font-weight: 500;
      transition: 0.3s;

      &:hover {
        color: white;
      }

      &.active {
        border-color: var(--main_accent);
        color: white;

        &::before {
          border-left-color: var(--main_accent);
          border-bottom-color: var(--main_accent);
        }

        &::after {
          opacity: 1;
        }

        svg {
          transform: rotate(90deg);
        }
      }

      &.active ~ .changelog_logs-list {
        display: flex;
      }

      &::before {
        content: "";
        position: absolute;
        width: 0.8rem;
        height: 0.8rem;
        left: -0.5rem;
        transform: rotate(45deg);
        background-color: var(--global_blue-darker);
        border-left: 0.05rem solid var(--global_blue-soft);
        border-bottom: 0.05rem solid var(--global_blue-soft);
        transition: 0.3s;
      }

      &::after {
        content: "";
        position: absolute;
        width: 0.3rem;
        left: -2.55rem;
        top: 1.9rem;
        opacity: 0;
        height: 0.3rem;
        border-radius: 100%;
        background-color: var(--main_accent);
        transition: 0.3s;
      }

      p {
        margin: 0;
        font-size: 1.1rem;
      }

      svg {
        transition: 0.3s;

        path {
          fill: var(--secondary_yellow);
        }
      }
    }
  }

  &_title {
    h1 {
      color: var(--main_accent);
      font-size: 3.1rem;
      margin: 0;
    }

    p {
      color: var(--secondary_yellow);
      font-weight: 500;
      font-size: 1.1rem;
    }
  }

  &_menu {
    width: 100%;
    margin-top: 6.8rem;
    border-top: 0.05rem solid rgba(255, 255, 255, 0.05);
    border-bottom: 0.05rem solid rgba(255, 255, 255, 0.05);
    margin-bottom: 4rem;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.307));
      width: 100%;
      height: 300%;
    }

    .main_container {
      display: flex;
      justify-content: space-between;
      height: 100%;
      align-items: center;
      position: relative;
      z-index: 1;

      ul {
        display: flex;
        padding: 0;
        gap: 5rem;
        height: 100%;

        margin: 0;

        li {
          list-style: none;
          height: 100%;
          box-sizing: border-box;

          button {
            cursor: pointer;
            background-color: transparent;
            font-size: 1.3rem;
            font-weight: 600;
            border: none;
            font-family: "Poppins";
            color: var(--secondary_yellow);
            padding: 2.5rem 0;
            height: 100%;
            box-sizing: border-box;
            position: relative;
            transition: 0.3s;
            outline: none;

            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              width: 0%;
              transition: 0.3s;
              height: 0.1rem;
              background-color: var(--main_accent);
            }

            &:hover {
              color: var(--main_accent);
            }

            &.active {
              color: var(--main_accent);

              &::before {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

// Shop

.shop_cart {
  .main_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-items {
    width: 59%;

    h1 {
      color: white;
      font-size: 2.3rem;
      margin: 0;
      margin-bottom: 2rem;
    }

    &_info:not(:last-child) {
      border-bottom: 0.05rem solid rgba(255, 255, 255, 0.05);
    }

    &_info {
      display: flex;
      align-items: center;
      padding: 2.2rem 0;

      &-delete {
        button {
          border-radius: 100%;
          background-color: var(--global_red-soft);
          width: 2.4rem;
          height: 2.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          cursor: pointer;

          &:hover {
            svg {
              path {
                fill: white;
              }
            }
          }

          svg {
            path {
              transition: 0.3s;
              fill: var(--global_red-warning);
            }
          }
        }
      }

      &-cost {
        display: flex;
        align-items: center;
        gap: 0.7rem;
        justify-content: center;
        color: var(--main_accent);
        font-weight: 600;
        width: 6rem;

        span {
          background-color: var(--main_accent);
          color: var(--global_blue-darker);
          width: 1rem;
          font-weight: 700;
          height: 1rem;
          display: flex;
          border-radius: 100%;
          align-items: center;
          font-size: 0.7rem;
          justify-content: center;
        }
      }

      &-product {
        width: 50%;
        display: flex;
        align-items: center;
        gap: 1.5rem;

        div:nth-child(1) {
          width: 4.8rem;
          height: 3.5rem;
          border-radius: 0.3rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        div:nth-child(2) {
          font-weight: 600;

          p {
            margin: 0;
            color: white;
          }

          span {
            color: var(--secondary_yellow);
            font-weight: 500;
            font-size: 0.9rem;
          }
        }
      }

      &-quantity {
        position: relative;
        width: 5.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--global_blue-darker);
        height: fit-content;
        border-radius: 0.3rem;
        height: 1.5rem;
        padding: 0.5rem;

        input {
          width: 100%;
          background-color: transparent;
          appearance: none;
          color: var(--secondary_yellow);
          border: none;
          text-align: center;
          font-size: 1rem;
          font-family: "Poppins";
          pointer-events: none;
          font-weight: 500;
        }

        button {
          opacity: 0.5;
          font-size: 1.2rem;
          font-family: "Poppins";
          background-color: transparent;
          border: none;
          color: var(--secondary_yellow);
          transition: 0.3s;
          cursor: pointer;

          &:hover {
            opacity: 1;
            color: white;
          }
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        gap: 3rem;

        div:nth-child(4) {
          width: auto;
          right: 0;
          position: absolute;
        }
      }
    }

    &_table {
      justify-content: flex-start;
      color: var(--secondary_yellow);
      border-bottom: 0.15rem solid var(--global_blue-soft);
      border-top: 0.05rem solid var(--global_blue-soft);
      padding: 1.3rem 0;
      font-weight: 500;

      div:nth-child(1) {
        width: 50%;
      }

      div:nth-child(2) {
        text-align: center;
        width: 6.4rem;
      }

      div:nth-child(3) {
        text-align: center;
        width: 6.4rem;
      }
    }
  }

  &-summary {
    width: 33.5%;

    h1 {
      color: white;
      font-size: 2.3rem;
      margin: 0;
      margin-bottom: 2rem;
    }

    ul {
      padding: 0;
      margin: 0;
      background-color: var(--global_blue-darker);
      border-radius: 0.4rem;
      border: 0.05rem solid var(--global_blue-soft);
      margin-bottom: 1.5rem;

      li:not(:last-child) {
        border-bottom: 0.05rem solid rgba(255, 255, 255, 0.05);
      }

      li {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 2rem;

        div:nth-child(2) {
          display: flex;
          align-items: center;
          gap: 0.6rem;
          color: var(--main_accent);
          font-weight: 500;

          span {
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1rem;
            height: 1rem;
            background-color: var(--main_accent);
            color: var(--global_blue-darker);
            font-size: 0.7rem;
            font-weight: 700;
          }
        }

        div:nth-child(1) {
          font-weight: 500;
          color: white;

          span {
            color: var(--secondary_yellow);
          }
        }

        div {
          p {
            margin: 0;
          }
        }
      }
    }

    &_complete {
      button {
        border: none;
        background-color: var(--button_main);
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 0.25rem;
        height: 4rem;
        font-size: 1.1rem;
        letter-spacing: 0.05rem;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        overflow: hidden;
        transition: 0.3s;
        position: relative;
        cursor: pointer;
        font-family: "Strong Sword";

        &:hover {
          box-shadow: inset 0 0 1rem var(--main_accent);

          &::before {
            left: -70%;
            opacity: 0.6;
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 120%;
          height: 100%;
          background-color: var(--main_accent);
          left: -100%;
          top: 0;
          filter: blur(4rem);
          opacity: 0;
          transition: 0.5s;
        }
      }
    }

    &_total {
      display: flex;
      background-color: var(--global_blue-soft);
      align-items: center;
      justify-content: space-between;
      color: white;
      border-radius: 0.4rem;
      padding: 1.2rem 2rem;
      font-weight: 600;
      margin-bottom: 2rem;

      div {
        display: flex;
        align-items: center;
        gap: 0.7rem;
        font-size: 1.05rem;

        p {
          color: var(--main_accent);
          font-weight: 600;
          margin: 0;
        }

        span {
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1rem;
          height: 1rem;
          background-color: var(--main_accent);
          color: var(--global_blue-darker);
          font-size: 0.7rem;
          font-weight: 700;
        }
      }
    }

    &_select {
      margin-bottom: 1.5rem;

      p {
        color: var(--secondary_yellow);
        font-weight: 500;
        margin: 0;
        margin-bottom: 1rem;
        font-size: 0.9rem;
      }

      div {
        position: relative;
        display: flex;
        align-items: center;

        &::before {
          content: ">";
          position: absolute;
          right: 1.2rem;
          opacity: 0.5;
          color: white;
          z-index: 1;
          font-size: 1.5rem;

          transform: rotate(90deg);
        }
      }

      select {
        width: 100%;
        background-color: black;
        border: 0.05rem solid rgba(255, 255, 255, 0.15);
        appearance: none;
        color: white;
        font-family: "Poppins";
        padding: 1.2rem 1.8rem;
        font-size: 0.9rem;
        border-radius: 0.4rem;
        font-weight: 500;
        transition: 0.3s;
        cursor: pointer;
        position: relative;

        &:hover {
          border-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

.shop_view {
  .main_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .shop_section {
    margin-top: 7rem !important;
    padding-top: 5rem;
    margin-bottom: 0 !important;
  }

  .global_page-navIndicator {
    margin-bottom: 4.7rem;
  }

  &-item_description {
    width: 53.5%;
    position: sticky;
    top: 6rem;
    height: fit-content;

    &-img {
      width: 100%;
      height: 28rem;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        border: 1.5rem solid transparent;
        border-image: url(./assets/img/frames/frame_golden.png);
        border-image-slice: 35 35 fill;
        left: -0.2rem;
        right: -0.2rem;
        bottom: -0.3rem;
        top: -0.2rem;
        pointer-events: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2.4rem;
      }
    }

    &-text {
      margin-top: 2.5rem;

      h1 {
        color: white;
        text-align: center;
        font-weight: 600;
        font-size: 1.6rem;
        margin: 0;
        margin-bottom: 1rem;
        font-family: "Poppins";
      }

      p {
        color: var(--secondary_yellow);
        text-align: center;
        font-size: 0.9rem;
        font-weight: 500;
        opacity: 0.5;
        letter-spacing: 0.02rem;
        width: 90%;
        margin: 0 auto;
      }
    }
  }

  &-item_info {
    width: 38.4%;

    &-buy {
      &_button {
        button {
          border: none;
          background-color: var(--button_main);
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-radius: 0.3rem;
          height: 4.4rem;
          font-size: 1.1rem;
          letter-spacing: 0.05rem;
          color: white;
          text-transform: uppercase;
          font-weight: bold;
          overflow: hidden;
          transition: 0.3s;
          position: relative;
          cursor: pointer;
          font-family: "Strong Sword";

          &:hover {
            box-shadow: inset 0 0 1rem var(--main_accent);

            &::before {
              left: -70%;
              opacity: 0.6;
            }
          }

          &::before {
            content: "";
            position: absolute;
            width: 120%;
            height: 100%;
            background-color: var(--main_accent);
            left: -100%;
            top: 0;
            filter: blur(4rem);
            opacity: 0;
            transition: 0.5s;
          }
        }
      }

      p {
        font-weight: 600;
        color: white;
        margin: 0;
        margin-bottom: 1.7rem;
      }

      &_price {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
        padding: 0.7rem 0.7rem 0.7rem 1.5rem;
        box-sizing: border-box;
        margin-bottom: 2rem;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: var(--main_accent);
          opacity: 0.1;
          border-radius: 0.3rem;
        }

        div:nth-child(2) {
          background-color: rgba(0, 0, 0, 0.3);
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 6rem;
          border-radius: 0.2rem;
          height: 2.5rem;

          span {
            position: absolute;
            cursor: pointer;
            color: var(--secondary_yellow);
            font-size: 0.9rem;
            opacity: 0.5;
            height: 100%;
            width: 2.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0);
            border-radius: 0.2rem;
            transition: 0.3s;
            z-index: 2;

            svg {
              path {
                fill: var(--secondary_yellow);
              }
            }

            &:hover {
              opacity: 1;
              background-color: rgba(255, 255, 255, 0.02);
            }
          }

          span:nth-child(1) {
            left: 0rem;
          }

          span:nth-child(3) {
            right: 0rem;
          }

          input {
            font-family: "Poppins";
            font-weight: 600;
            font-size: 1rem;
            width: 1.9rem;
            position: absolute;
            background-color: transparent;
            cursor: not-allowed;
            color: var(--secondary_yellow);
            border: none;
            text-align: center;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            appearance: textfield;
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        div:nth-child(1) {
          display: flex;
          align-items: center;
          gap: 0.8rem;

          span:nth-child(1) {
            display: flex;
            width: 1.4rem;
            height: 1.4rem;
            background-color: var(--main_accent);
            color: black;
            font-weight: 700;
            border-radius: 100%;
            font-size: 0.85rem;
            align-items: center;
            justify-content: center;
          }

          span:nth-child(2) {
            color: var(--main_accent);
            font-weight: 600;
            font-size: 1.1rem;
          }
        }

        div {
          position: relative;
          z-index: 1;
        }
      }
    }

    &-msg {
      margin-top: 2rem;

      p {
        color: var(--secondary_yellow);
        font-size: 0.9rem;
        font-weight: 500;
        opacity: 0.4;

        a {
          color: white;
          transition: 0.3s;
          text-decoration: none;

          &:hover {
            color: var(--main_accent);
          }
        }
      }
    }

    &-complement {
      margin-bottom: 3.5rem;

      p {
        font-weight: 600;
        color: white;
        margin: 0;
        margin-bottom: 1.7rem;
      }

      ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 1.4rem;

        li {
          list-style: none;
          padding: 0.8rem;
          background: var(--shop_gradient);
          border-radius: 0.4rem;
          display: flex;
          align-items: center;
          gap: 1.3rem;
          color: var(--secondary_yellow);
          font-weight: 500;
          font-size: 0.95rem;

          span:nth-child(1) {
            display: inline-flex;
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 0.25rem;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    &-name {
      padding-bottom: 1.8rem;
      margin-bottom: 2.5rem;
      border-bottom: 0.15rem solid rgba(255, 255, 255, 0.05);

      h1 {
        color: white;
        font-weight: 600;
        font-family: "Poppins";
        margin: 0;
        font-size: 2.5rem;
        margin-bottom: 0.5rem;
      }

      span {
        margin: 0;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.9rem;
        color: var(--main_accent);
      }
    }
  }
}

.shop {
  position: relative;
  z-index: 1;
  padding: 7.8rem 0;

  &_section {
    padding-top: 3rem;
    border-top: 0.15rem solid rgba(255, 255, 255, 0.05);
    margin-bottom: 4rem;
    width: 100%;

    .global_showMore {
      margin-top: 3rem !important;
    }

    &-item_img {
      width: 100%;
      height: 11rem;
      overflow: hidden;
      border-radius: 2.4rem 2.4rem 0 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }
    }

    &-item_info {
      background: var(--shop_gradient);
      padding: 2rem 2.5rem 2rem 2.5rem;
      height: 7rem;
      display: flex;
      flex-direction: column;
      border-radius: 0 0 2.4rem 2.4rem;
      justify-content: space-between;

      p {
        margin: 0;
        color: white;
        font-size: 1rem;
        font-weight: 600;
      }

      div:nth-child(2) {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        span:nth-child(1) {
          width: 1.3rem;
          height: 1.3rem;
          background-color: var(--main_accent);
          display: flex;
          align-items: center;
          justify-content: center;
          color: black;
          font-size: 0.9rem;
          font-weight: 700;
          border-radius: 100%;
        }

        span:nth-child(2) {
          color: var(--main_accent);
          font-size: 1.1rem;
          font-weight: 600;
        }
      }
    }

    &-title {
      margin-bottom: 2.7rem !important;

      h1 {
        font-size: 2rem !important;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      gap: 2.7rem;

      li {
        list-style: none;
        width: 17.3rem;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          border: 1.3rem solid transparent;
          border-image: url(./assets/img/frames/global_frame.png);
          border-image-slice: 35 35 fill;
          left: -0.2rem;
          right: -0.2rem;
          bottom: -0.3rem;
          top: -0.2rem;
          pointer-events: none;
        }

        &:hover {
          a {
            .shop_section-item_img {
              img {
                transform: scale(1.15);
              }
            }
          }
        }

        a {
          text-decoration: none;
        }
      }
    }
  }

  &_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--global_body);
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    z-index: 1;
    margin-top: 4.5rem;

    div:nth-child(1) {
      display: flex;
      gap: 2rem;
      color: var(--secondary_yellow);
      align-items: center;
      font-size: 1rem;
      font-weight: 500;
      flex-wrap: wrap;

      p {
        margin: 0;
      }

      ul {
        display: flex;
        padding: 0;
        gap: 1rem;
        flex-wrap: wrap;

        li {
          list-style: none;

          button {
            text-decoration: none;
            font-family: "Poppins";
            background-color: transparent;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0.7rem 2.2rem;
            border: none;
            color: var(--secondary_yellow);
            border-radius: 0.2rem;
            font-size: 0.9rem;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              color: var(--main_accent);
            }

            &.active {
              color: var(--main_accent);
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }
  }

  &_section-title {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin-bottom: 3.5rem !important;

    h1 {
      color: white;
      margin: 0;
      letter-spacing: 0.05rem;
      font-size: 2.2rem !important;
    }

    svg {
      opacity: 0.6;
      font-size: 2rem;

      path {
        fill: var(--secondary_yellow);
      }
    }
  }

  &_special {
    position: relative;

    &-slider {
      overflow: hidden;
      padding: 0.2rem;

      &_pagination {
        top: 1.6rem !important;
        right: 0 !important;
        display: flex;
        justify-content: flex-end;
        height: 2rem;
        gap: 0.7rem;

        .swiper-pagination-bullet {
          width: 0.75rem;
          height: 0.75rem;
          background-color: white;

          &-active {
            background-color: var(--main_accent);
          }
        }
      }

      &_prev {
        left: -6rem;
        top: 19.5rem;
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 100%;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.15rem solid var(--global_blue-soft);
        box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.549);
        transition: 0.3s;

        &:hover {
          border-color: var(--secondary_yellow);
        }

        svg {
          object-fit: none !important;
          font-size: 1.3rem;
          width: auto;
          height: auto;

          path {
            fill: var(--main_accent);
          }
        }

        &::after {
          display: none;
        }
      }

      &_next {
        right: -6rem;
        top: 19.5rem;
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 100%;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.15rem solid var(--global_blue-soft);
        box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.549);
        transition: 0.3s;

        &:hover {
          border-color: var(--secondary_yellow);
        }

        svg {
          object-fit: none !important;
          font-size: 1.3rem;
          width: auto;
          height: auto;

          path {
            fill: var(--main_accent);
          }
        }

        &::after {
          display: none;
        }
      }
    }
  }

  &_special-item {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      border: 1.7rem solid transparent;
      border-image: url(./assets/img/frames/frame_golden.png);
      border-image-slice: 35 35 fill;
      left: -0.2rem;
      right: -0.2rem;
      bottom: -0.3rem;
      top: -0.2rem;
      pointer-events: none;
    }
  }

  &_item {
    position: relative;
    text-decoration: none;

    &:hover {
      .shop_item-img {
        img {
          transform: scale(1.15);
        }
      }
    }

    &-img {
      width: 100%;
      height: 15rem;
      border-radius: 2.4rem 2.4rem 0 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        transition: 0.3s;
        object-fit: cover;
      }
    }

    &-info {
      background: var(--shop_gradient-special);
      padding: 2rem 3rem 2.5rem 3rem;
      border-radius: 0 0 2.4rem 2.4rem;
      height: 7.7rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      p {
        margin: 0;
      }

      &_name {
        font-size: 1rem;
        color: white;
        font-weight: 600;
        font-size: 1.1rem;
        letter-spacing: 0.02rem;
      }

      &_price {
        display: flex;
        align-items: center;
        gap: 1rem;

        span {
          display: flex;
        }

        span:nth-child(1) {
          width: 1.5rem;
          height: 1.5rem;
          background-color: var(--main_accent);
          color: black;
          font-weight: bold;
          border-radius: 100%;
          align-items: center;
          justify-content: center;
        }

        span:nth-child(2) {
          gap: 0.6rem;
          font-weight: 600;
          font-size: 1.1rem;

          .shop_item-info_price-discount {
            text-decoration: line-through;
            color: var(--secondary_yellow);
          }

          p {
            color: var(--main_accent);
          }
        }
      }

      &_tag {
        position: absolute;
        right: 2.5rem;
        bottom: 2.5rem;
        color: var(--secondary_yellow);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.95rem;
        letter-spacing: 0.05rem;
      }
    }
  }
}

// Dashboard

.dashboard {
  position: relative;
  z-index: 1;
  padding: 10rem 0;

  button {
    cursor: pointer;
    font-family: "Poppins";
    font-weight: 500;
  }

  .main_container {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &_menu-mobile {
    display: none;
  }

  &_content {
    width: 67%;
    display: flex;
    flex-direction: column;
    gap: 2.8rem;

    &-characters {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      li {
        list-style: none;
        position: relative;
        width: 100%;
        height: 12.6rem;
        overflow: hidden;
        border-radius: 0.4rem;
        border: 0.05rem solid var(--global_blue-soft);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;

        div:nth-child(1) {
          position: absolute;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        div:nth-child(2) {
          position: relative;
          z-index: 1;
          height: 100%;
          padding: 2rem 3rem;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h1 {
            margin: 0;
            font-size: 1.4rem;
            color: white;
            margin-bottom: 0.3rem;

            span {
              font-size: 1.1rem;
              color: var(--secondary_yellow);

              span {
                color: var(--main_accent);
              }
            }
          }

          span {
            color: var(--secondary_yellow);
          }
        }

        div:nth-child(3) {
          width: 100%;
          display: flex;
          justify-content: space-between;
          position: relative;
          z-index: 1;
          box-sizing: border-box;
          align-items: center;
          background-color: var(--global_blue-darker);
          padding: 1rem 1rem 1rem 3rem;
          flex-wrap: wrap;
          gap: 1rem;

          p {
            margin: 0;
            color: var(--secondary_yellow);
            font-weight: 600;
            font-size: 0.95rem;

            span {
              color: white;
            }
          }

          ul {
            display: flex;
            margin: 0 !important;
            padding: 0 !important;
            box-sizing: border-box;
            flex-wrap: wrap;
            gap: 1rem;

            li {
              width: auto;
              height: auto;
              margin: 0;
              border: none;
              border-radius: 0;

              button {
                width: auto !important;
                height: auto !important;
                padding: 0.5rem 1.4rem;
                margin: 0;
                color: var(--secondary_yellow);
                border-radius: 0.2rem !important;
                border: none !important;

                &:hover {
                  color: var(--main_accent);
                }
              }
            }
          }
        }
      }
    }

    .dashboard_ipConnect {
      position: relative;

      table {
        width: 100%;
        text-align: left;
        background-color: var(--global_blue-darker);
        border-radius: 0.5rem;
        overflow: hidden;
        border: 0.05rem solid var(--global_blue-soft);
        border-collapse: collapse;
        position: relative;

        tr {
          th {
            background-color: var(--global_blue-soft);
            padding: 1.5rem 2rem;
            font-weight: 500;
            color: white;
          }

          td {
            padding: 1.5rem 2rem;
            border-bottom: 0.05rem solid rgba(255, 255, 255, 0.05);
            color: var(--secondary_yellow);
            width: 50%;
            font-weight: 500;
          }
        }
      }

      &-message {
        position: absolute;
        color: var(--main_accent) !important;
        z-index: 1;
        right: 2rem;
        top: 1.5rem;
        text-decoration: underline;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          color: white !important;

          div {
            opacity: 1;
            bottom: 150%;
          }
        }

        div {
          position: absolute;
          bottom: 100%;
          transition: 0.3s;
          opacity: 0;
          color: var(--secondary_yellow);
          right: 0;
          text-align: center;
          font-size: 0.9rem;
          width: 15rem;
          background-color: var(--global_blue-darker);
          padding: 1rem;
          border-radius: 0.4rem;
          pointer-events: none;
          border: 0.05rem solid var(--global_blue-soft);
        }
      }
    }

    &-logs_tabs {
      display: flex;
      align-items: center;
      gap: 1rem;

      button {
        width: auto !important;
        padding: 0 1.8rem;
        color: var(--secondary_yellow) !important;
        height: 3rem !important;
        border: 0.05rem solid transparent !important;
        gap: 0.6rem;

        svg {
          width: 1.5rem;

          path {
            fill: var(--secondary_yellow);
          }
        }

        &.active {
          color: var(--main_accent) !important;
          border-color: var(--main_accent) !important;

          svg {
            path {
              fill: var(--main_accent);
            }
          }
        }

        &:hover {
          color: var(--main_accent) !important;
        }
      }
    }

    .dashboard_orderHistory {
      flex-direction: column;

      .dashboard_orderHistory-ul {
        margin: 0;
        padding: 0;
        gap: 3rem;
        display: flex;
        flex-direction: column;

        li {
          background-color: var(--global_blue-soft);
          list-style: none;
          width: 100%;
          border-radius: 0.5rem;
          border: 0.05rem solid var(--global_blue-soft);
        }
      }

      &-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        padding: 2.2rem;
        gap: 2rem;

        div {
          display: flex;

          p {
            margin: 0;
          }
        }
      }

      &-price {
        padding: 0.5rem 1.5rem;
        border-radius: 0.2rem;
        color: var(--main_accent);
        font-weight: 600;
        border: 0.05rem solid var(--main_accent);
      }

      &-coin {
        gap: 4rem;

        div {
          flex-direction: column;

          p {
            color: var(--secondary_yellow);
            font-weight: 500;
            font-size: 0.85rem;
            margin-bottom: 0.3rem;
          }

          div {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            flex-direction: row;

            span {
              width: 1rem;
              height: 1rem;
              background-color: var(--main_accent);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              color: var(--global_blue-darker);
              font-weight: 700;
              font-size: 0.7rem;
            }

            p {
              font-size: 1rem;
              color: var(--main_accent);
              margin: 0;
            }
          }
        }
      }

      &-product {
        align-items: center;
        gap: 1.2rem;

        &_img {
          display: flex;
          width: 5.5rem;
          height: 3.5rem;
          border-radius: 0.3rem;
          border: 0.05rem solid var(--secondary_yellow);
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        div {
          flex-direction: column;

          p:nth-child(1) {
            color: white;
            font-weight: 500;
          }

          p:nth-child(2) {
            color: var(--secondary_yellow);
            font-size: 0.9rem;
            font-weight: 500;
          }

          .dashboard_orderHistory-verifying {
            color: gray;
          }

          .dashboard_orderHistory-completed {
            color: var(--main_accent);
          }

          .dashboard_orderHistory-rejected {
            color: var(--global_red);
            display: inline-flex;
            align-items: center;
          }

          .dashboard_orderHistory-rejected_message {
            width: 1.1rem;
            height: 1.1rem;
            color: var(--secondary_yellow);
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;
            font-size: 0.7rem;
            cursor: pointer;
            transition: 0.3s;
            font-weight: 500;
            top: -0.1rem;
            left: 0.15rem;

            &:hover {
              color: var(--global_red);

              span {
                bottom: 150%;
                opacity: 1;
              }
            }

            span {
              position: absolute;
              pointer-events: none;
              right: 0;
              width: 14rem;
              height: auto;
              bottom: 100%;
              transition: 0.3s;
              background-color: var(--global_blue-darker);
              border-radius: 0.3rem;
              padding: 1rem;
              border: 0.05rem solid var(--global_blue-soft);
              text-align: center;
              opacity: 0;
              color: var(--secondary_yellow) !important;
            }
          }
        }
      }

      &-details {
        display: flex;
        background-color: var(--global_blue-darker);
        padding: 2.2rem;
        align-items: center;
        gap: 5rem;
        flex-wrap: wrap;

        p {
          margin: 0;
        }

        div {
          p {
            color: var(--secondary_yellow);
            opacity: 0.5;
            font-weight: 500;
            font-size: 0.85rem;
          }

          span {
            color: var(--secondary_yellow);
            font-weight: 500;
            font-size: 0.9rem;
          }
        }
      }
    }

    button {
      display: flex;
      background-color: var(--dashboard_blue-soft);
      border-radius: 0.3rem;
      display: flex;

      align-items: center;
      justify-content: center;
      width: 9rem;
      height: 2.6rem;
      border: none;
      font-size: 0.9rem;
      transition: 0.3s;

      &:hover {
        color: white;
      }
    }

    .dashboard_red-button {
      background-color: var(--global_red-soft) !important;
      color: var(--global_red-warning) !important;

      &:hover {
        color: white !important;
      }
    }

    .dashboard_yellow-button {
      background-color: var(--main_accent) !important;
      color: var(--global_blue-darker) !important;

      &:hover {
        background-color: var(--global_blue-soft) !important;
        color: white !important;
      }
    }

    &-title {
      h1 {
        margin: 0;
        color: white;
        font-size: 2.8rem;
      }
    }

    &-withList_title {
      padding: 1.5rem 3rem 1.5rem 3rem;
      color: white;
      font-weight: 600;
      font-size: 1.4rem;
    }

    &-withList_list {
      padding: 0;
      margin: 0;

      button {
        color: var(--main_accent);
      }

      &-gold {
        cursor: auto !important;

        &:hover {
          background-color: transparent !important;
        }

        span:nth-child(2) {
          p {
            color: var(--main_accent);
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.3rem;
            height: 2.3rem;
            transition: 0.3s;
            position: relative;

            &:hover {
              svg {
                transform: rotate(180deg);
              }

              &::before {
                opacity: 0.3;
              }
            }

            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: 100%;
              background-color: var(--main_accent);
              top: 0;
              left: 0;
              opacity: 0.2;
              transition: 0.3s;
            }

            svg {
              font-size: 0.9rem;
              transition: 0.3s;
              position: relative;
              z-index: 1;
              transition: 0.3s;

              path {
                fill: var(--main_accent);
              }
            }
          }
        }
      }

      li:not(:last-child) {
        border-bottom: 0.05rem solid var(--dashboard_blue-soft);
      }

      li {
        list-style: none;
        padding: 0;
        margin: 0;

        form {
          padding: 0rem 3rem;
          background-color: var(--dashboard_blue-darker_dropdown);
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          box-sizing: border-box;
          max-height: 0;
          overflow: hidden;
          transition: 0.6s;
          opacity: 0;

          p {
            color: var(--secondary_yellow);
            margin: 0;
            width: 100%;
            padding: 0;
            text-align: left;
            margin-bottom: 1rem;
          }

          div {
            justify-content: center !important;
            padding: 0;
            background-color: transparent;
            align-items: center;
            margin-bottom: 1.5rem !important;
            height: 3.3rem;
            max-height: 50rem !important;
            opacity: 1;

            svg {
              font-size: 1rem;
            }

            span:nth-child(4) {
              display: none;
            }
          }

          input {
            width: 100%;
            box-sizing: border-box;
          }

          button {
            color: var(--primary_blue);
          }
        }

        .global_toggleable {
          cursor: pointer;

          &:hover {
            background-color: var(--dashboard_blue-soft);
          }
        }

        div:nth-child(1) {
          padding: 0rem 2.5rem 0rem 3rem;
          height: 5.5rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.1rem;
          color: var(--secondary_yellow);
          font-weight: 500;
          transition: 0.3s;
          flex-wrap: wrap;

          p {
            margin: 0;
          }

          &.active {
            span:nth-child(2) {
              span {
                background-color: var(--global_red-soft);

                svg {
                  transform: rotate(90deg);

                  path {
                    fill: var(--global_red-warning);
                  }
                }
              }
            }
          }

          &.active ~ form {
            max-height: 50rem;
            padding: 3rem;
            opacity: 1;
            transition: 1s;
          }

          span:nth-child(1) {
            font-weight: 600;
          }

          span:nth-child(2) {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            span {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 2.3rem;
              height: 2.3rem;
              border-radius: 100%;
              background-color: var(--dashboard_blue-soft);
              transition: 0.3s;

              svg {
                font-size: 0.9rem;
                transition: 0.3s;

                path {
                  fill: var(--secondary_yellow);
                }
              }
            }
          }
        }
      }
    }

    &-wrapper {
      background-color: var(--dashboard_blue-darker);
      border: 0.05rem solid var(--dashboard_blue-soft);
      border-radius: 0.6rem;
      box-sizing: border-box;
      overflow: hidden;
    }

    &-noList {
      padding: 2.5rem 3rem;

      h1 {
        margin: 0;
        font-family: "Poppins";
        font-weight: 600;
      }
    }

    &-warning {
      background-color: transparent !important;
      border: 0.05rem solid var(--global_red-soft) !important;

      h1 {
        color: var(--global_red-warning);
        font-size: 1.4rem;
      }

      p {
        color: var(--secondary_yellow);
      }
    }

    &-profile {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 2rem;

      &_avatarButton {
        display: flex;
        align-items: center;

        button {
          color: var(--main_accent);
        }
      }

      &_avatarProfile {
        display: flex;
        gap: 2rem;

        span:nth-child(1) {
          width: 4rem;
          height: 4rem;
          border-radius: 100%;
          overflow: hidden;
          border: 0.15rem solid var(--primary_blue);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        span:nth-child(2) {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h1 {
            margin: 0;
            font-family: "Poppins";
            font-weight: 600;
            color: white;
            font-size: 1.3rem;
            letter-spacing: 0.05rem;
          }

          p {
            margin: 0;
            color: var(--secondary_yellow);
            font-weight: 500;
            letter-spacing: 0.05rem;
          }
        }
      }
    }
  }

  &_menu {
    padding: 0;
    background-color: var(--dashboard_blue-darker);
    height: fit-content;
    padding: 2.5rem;
    position: sticky;
    top: 8rem;
    border-radius: 2.8rem;
    width: 22%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &::after {
      content: "";
      position: absolute;
      border: 1.3rem solid transparent;
      border-image: url(./assets/img/frames/global_frame.png);
      border-image-slice: 35 35 fill;
      left: -0.2rem;
      right: -0.2rem;
      bottom: -0.3rem;
      top: -0.2rem;
      pointer-events: none;
    }

    li {
      list-style: none;
      width: 100%;

      button {
        background-color: transparent;
        font-family: "Strong Sword";
        color: var(--global_red-warning);
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;
        font-size: 1rem;
        margin: 0 auto;
        margin-top: 0.8rem;
        cursor: pointer;
        transition: 0.3s;
        font-family: "Poppins";

        &:hover {
          color: white;
        }

        svg {
          path {
            fill: var(--global_red-warning);
          }
        }
      }

      &.active {
        a {
          background-color: var(--dashboard_blue-soft);
          color: var(--main_accent);

          svg {
            transform: rotate(0);

            path {
              fill: var(--main_accent);
            }
          }
        }
      }

      a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0.8rem 0 0.9rem 0;
        box-sizing: border-box;
        border-radius: 0.3rem;
        text-decoration: none;
        font-family: "Strong Sword";
        font-weight: bold;
        color: var(--secondary_yellow);
        font-size: 1.1rem;
        letter-spacing: 0.05rem;
        border: 0.1rem solid var(--dashboard_blue-soft);
        transition: 0.3s;

        &:hover {
          background-color: var(--dashboard_blue-soft);
        }

        svg {
          position: absolute;
          right: 1.5rem;
          font-size: 0.85rem;
          transform: rotate(90deg);

          path {
            fill: var(--secondary_yellow);
          }
        }
      }
    }
  }
}

// Sign Up

.sign-up {
  position: relative;
  z-index: 1;
  padding: 10rem 0;

  .main_container {
    display: flex;
    justify-content: flex-start;
  }
}

// MainPage Styles

.main_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  animation: levitate_bg-dragon 6s infinite linear;
  overflow: hidden;
  height: 101rem;

  &-effect {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    left: 0;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 70rem;
      background: url(./assets/img/rocks.webp) top no-repeat;
      top: 0;
      right: 0;
      opacity: 0;
      animation: fly_main-effect 30s infinite linear;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 70rem;
      background: url(./assets/img/rocks.webp) top no-repeat;
      top: 0;
      right: 0;
      opacity: 0;
      animation: fly_main-effect 30s 15s infinite linear;
    }
  }

  &::before {
    content: "";
    position: absolute;
    background: url(./assets/img/bg/main/animated-dragon.avifs) top no-repeat;
    background: url(./assets/img/bg/main/dragon_effect-off.webp) top no-repeat;
    width: 100%;
    height: 101rem;
    top: 0;
    left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    background: url(./assets/img/bg/main/dragon_effect-on.webp) top no-repeat;
    //background: url(./assets/img/bg/main/animated-dragon.avifs) top no-repeat;
    //background-size: contain;
    width: 100%;
    height: 101rem;
    top: 0;
    left: 0;
    animation: opacity_bg-dragon 10s infinite;
  }
}

@keyframes fly_main-effect {
  0% {
    top: 20rem;
    right: -30rem;
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    top: -50rem;
    right: 50rem;
    opacity: 0;
  }
}

@keyframes levitate_bg-dragon {
  0% {
    top: 0;
  }

  50% {
    top: -1rem;
  }

  100% {
    top: 0;
  }
}

@keyframes opacity_bg-dragon {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  70% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.main_container {
  width: 85%;
  max-width: 77.4rem;
  margin: 0 auto;
}

.main_header {
  width: 100%;
  height: 38rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 7rem;

  &-logo {
    img {
      width: 40rem;
      animation: scale_header-logo 6s infinite linear;
    }

    @keyframes scale_header-logo {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.03);
      }

      100% {
        transform: scale(1);
      }
    }

    video {
      width: 58rem;
      position: relative;
      top: 7rem;
    }
  }

  &-slogan {
    margin-bottom: 4.5rem;

    h1 {
      background: -webkit-linear-gradient(
        top,
        rgb(255, 255, 255),
        rgb(170, 170, 170)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      filter: drop-shadow(0 0.5rem 0.5rem rgba(0, 0, 0, 0.4));
      font-size: 3.75rem;
    }
  }

  &-link {
    box-sizing: border-box;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 30rem;
      margin: 0 auto;
      box-sizing: border-box;

      &:hover {
        span:nth-child(1) {
          transform: scale(1.1);

          svg {
            path {
              fill: white;
            }
          }
        }
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span:nth-child(1) {
        position: relative;
        z-index: 3;
        font-family: "Strong Sword";
        padding: 0;
        color: white;
        text-transform: uppercase;
        gap: 0.9rem;
        font-size: 1.1rem;
        letter-spacing: 0.03rem;
        font-weight: bold;
        transition: 0.5s;

        svg {
          font-size: 0.85rem;

          path {
            fill: var(--main_accent);
            transition: 0.3s;
          }
        }
      }

      span:nth-child(2) {
        position: absolute;
        z-index: 3;

        img {
          width: 29rem;
        }
      }

      span:nth-child(3) {
        position: absolute;
        z-index: 2;

        pointer-events: none;

        @keyframes opacity_header-link {
          0% {
            opacity: 0;
          }

          50% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 50%;
          height: 40%;
          left: 1rem;
          filter: blur(2rem);
          background-color: white;
          animation: sliding_header-link 6s infinite linear;
          opacity: 0.4;
        }

        &::after {
          content: "";
          position: absolute;
          width: 8cm;
          height: 40%;
          left: 1rem;
          filter: blur(3rem);
          background-color: white;
          opacity: 0.3;
          animation: sliding_header-link 6s infinite linear;
        }

        @keyframes sliding_header-link {
          0% {
            left: -1rem;
            opacity: 0;
          }

          40% {
            opacity: 0.7;
          }

          100% {
            left: 15rem;
            opacity: 0;
          }
        }

        img {
          width: 28rem;
          animation: opacity_header-link 3s linear infinite;
        }
      }

      span:nth-child(4) {
        position: absolute;
        pointer-events: none;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: -2.5rem;
          filter: blur(2rem);
          background-color: black;
          opacity: 0.2;
        }

        img {
          width: 27.5rem;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}

.main_history {
  position: relative;
  z-index: 1;
  padding-bottom: 1rem;

  &::before {
    content: "";
    width: 100%;
    background: url(./assets/img/bg/main/history_bg.webp) top no-repeat;
    height: 112rem;
    position: absolute;
    pointer-events: none;
    top: -54rem;
    left: 0;
  }

  &-title {
    display: flex;
    align-items: center;
    position: relative;
    gap: 1.8rem;
    z-index: 1;
    padding: 4rem 0 2.5rem 0;

    h1 {
      background: -webkit-linear-gradient(
        top,
        rgb(255, 255, 255),
        rgb(170, 170, 170)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      filter: drop-shadow(0 0.5rem 0.5rem rgba(0, 0, 0, 0.4));
      font-size: 2.8rem;
      font-weight: normal;
    }

    svg {
      font-size: 2.2rem;
      opacity: 0.4;

      path {
        fill: var(--secondary_yellow);
      }
    }

    &::before {
      content: "";
      background: url(./assets/img/frames/frame_line.png) top / cover no-repeat;
      width: 100%;
      height: 1.29rem;
      opacity: 0.5;
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  &-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    .history_old {
      div:nth-child(1) {
        &::after {
          content: "";
          position: absolute;
          border: 2rem solid transparent;
          border-image: url(./assets/img/frames/global_frame.png);
          border-image-slice: 35 35 fill;
          left: -0.2rem;
          right: -0.2rem;
          bottom: -0.3rem;
          top: -0.2rem;
          pointer-events: none;
        }
      }

      div:nth-child(2) {
        h1 {
          color: white;
        }

        p {
          color: var(--secondary_blue);
        }
      }

      div:nth-child(3) {
        span {
          color: var(--primary_blue);

          svg {
            path {
              fill: var(--primary_blue);
            }
          }
        }
      }
    }

    .history_new {
      div:nth-child(1) {
        &::after {
          content: "";
          position: absolute;
          border: 2rem solid transparent;
          border-image: url(./assets/img/frames/frame_golden.png);
          border-image-slice: 35 35 fill;
          left: -0.2rem;
          right: -0.2rem;
          bottom: -0.3rem;
          top: -0.2rem;
          pointer-events: none;
        }
      }

      div:nth-child(2) {
        h1 {
          color: var(--main_accent);
        }

        p {
          color: var(--secondary_yellow);
        }
      }

      div:nth-child(3) {
        span {
          color: var(--main_accent);

          svg {
            path {
              fill: var(--main_accent);
            }
          }
        }
      }
    }

    &_wrapper {
      width: 45%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      top: 8rem;
      height: fit-content;

      &.active {
        div:nth-child(2) {
          max-height: 50rem;
          transition: 1s;

          p {
            -webkit-line-clamp: 100;
            line-clamp: 100;
          }
        }

        div:nth-child(3) {
          span:nth-child(1) {
            display: none;
          }

          span:nth-child(2) {
            display: flex;
          }
        }
      }

      div:nth-child(1) {
        height: 23.2rem;
        position: relative;
        margin-bottom: 1.5rem;

        &::before {
          content: "";
          width: 100%;
          height: 80%;
          background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.734));
          bottom: 0;
          left: 0;
          position: absolute;
          border-radius: 2.4rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 3rem;
        }

        a {
          position: absolute;
          left: 2rem;
          top: 2rem;
          border-radius: 100%;
          height: 2.7rem;
          width: 2.7rem;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s;

          &:hover {
            background-color: rgba(0, 0, 0, 0.8);

            svg {
              opacity: 1;
            }
          }

          svg {
            opacity: 0.5;
            font-size: 1.1rem;
            transition: 0.3s;

            path {
              fill: white;
            }
          }
        }
      }

      div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        max-height: 8rem;
        transition: 0.6s;

        h1 {
          margin: 0;
          text-align: center;
          font-weight: normal;
        }

        p {
          margin: 0 auto;
          width: 85%;
          text-align: center;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      div:nth-child(3) {
        padding-bottom: 0.12rem;
        width: 8rem;
        margin: 0 auto;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 0.2rem;
        height: 2.7rem;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        span:nth-child(2) {
          display: none;
        }

        span {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.7rem;
          font-family: "Strong Sword";
          font-size: 1rem;

          svg {
            font-size: 0.8rem;
            position: relative;
            top: 0.1rem;
          }
        }
      }
    }
  }
}

.main_end-wrapper {
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 130rem;
    background: url(./assets/img/bg/main/wrapped_bg.webp) top no-repeat;
  }
}

.main_features {
  padding-bottom: 10rem;
  padding-top: 20.5rem;

  .main_container {
    overflow: visible !important;
  }

  &-title {
    width: 40%;
    margin-bottom: 10rem;
    position: relative;
    z-index: 1;

    h1 {
      background: -webkit-linear-gradient(
        top,
        rgb(255, 255, 255),
        rgb(170, 170, 170)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      filter: drop-shadow(0 0.5rem 0.5rem rgba(0, 0, 0, 0.4));
      font-size: 4.5rem;
      line-height: 5rem;
      font-weight: normal;
      margin: 0;
      margin-bottom: 2.5rem;
    }

    p {
      color: var(--secondary_yellow);
      font-weight: 500;
      letter-spacing: 0.02rem;
      font-size: 1.1rem;
    }
  }

  &-slider {
    position: relative;
    overflow: visible !important;

    .swiper-scrollbar {
      background-color: rgba(255, 255, 255, 0.05);
      width: 70% !important;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: -8.2rem;
      z-index: 2 !important;

      &-drag {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    &_content {
      min-height: 17rem;
      position: relative;

      &-wrapper {
        position: absolute;
        width: 100%;
        max-height: 17rem;
        transition: 0.5s;
        background-color: black;
        border-radius: 2.4rem;
        box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.2);

        &::after {
          content: "";
          position: absolute;
          border: 2rem solid transparent;
          border-image: url(./assets/img/frames/frame_brown-shaded.png);
          border-image-slice: 35 35 fill;
          left: -0.3rem;
          right: -0.3rem;
          bottom: -0.3rem;
          top: -0.3rem;
          z-index: 1;
          pointer-events: none;
        }

        &.active {
          max-height: 30rem;
          transition: 1.2s;

          div:nth-child(2) {
            max-height: 20rem;

            p {
              max-height: 15rem;
              opacity: 1;
            }
          }

          button {
            p:nth-child(2) {
              display: block;
            }

            p:nth-child(1) {
              display: none;
            }

            svg {
              rotate: 180deg;
              top: 0;
            }
          }
        }

        button {
          color: var(--main_accent);
          position: absolute;
          left: 0;
          bottom: -3.2rem;
          height: 2rem;
          background-color: transparent;
          border: none;
          font-family: "Strong Sword";
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-size: 1.1rem;
          margin-left: 2.8rem;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            color: white;
          }

          p:nth-child(2) {
            display: none;
          }

          svg {
            font-size: 0.8rem;
            position: relative;
            top: 0.1rem;
            transition: 0.6s;

            path {
              fill: var(--main_accent);
            }
          }
        }

        div:nth-child(1) {
          position: absolute;
          width: 100%;
          max-height: 17rem;
          background-color: black;
          display: flex;
          top: 0;
          border-radius: 3rem;
          overflow: hidden;

          a {
            position: absolute;
            top: 2rem;
            left: 2rem;
            z-index: 2;
            width: 2.6rem;
            height: 2.6rem;
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 100%;
            display: flex;
            align-items: center;
            font-size: 1.1rem;
            justify-content: center;
            transition: 0.3s;

            &:hover {
              background-color: rgba(0, 0, 0, 0.8);

              svg {
                opacity: 1;
              }
            }

            svg {
              transition: 0.3s;
              opacity: 0.6;

              path {
                fill: white;
              }
            }
          }

          img {
            width: 100%;
            border-radius: 2.4rem;
            min-height: 17rem;
            object-fit: cover;
            opacity: 0.7;
          }

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 80%;
            bottom: 0;
            left: 0;
            z-index: 1;
            border-radius: 2.4rem;
            background: linear-gradient(
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0),
              black
            );
          }
        }

        div:nth-child(2) {
          position: relative;
          z-index: 1;
          width: 100%;
          margin-top: 9rem;
          box-sizing: border-box;
          padding: 3rem;

          h1 {
            margin: 0;
            padding: 0;
            background: -webkit-linear-gradient(
              top,
              rgb(255, 255, 255),
              rgb(170, 170, 170)
            );
            -webkit-background-clip: text;
            font-size: 1.6rem;
            -webkit-text-fill-color: transparent;
            filter: drop-shadow(0 0.5rem 0.5rem rgba(0, 0, 0, 0.4));
          }

          p {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            margin: 0;
            transition: 0.5s;
            color: var(--secondary_yellow);
            font-weight: 500;
            font-size: 0.85rem;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}

.global_tabs {
  display: none;

  &.active {
    display: flex;
  }
}

.global_spinner-loading {
  position: relative;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    border-left: 0.12rem solid rgba(255, 255, 255, 0.4);
    border-top: 0.12rem solid rgba(255, 255, 255, 0.4);
    left: 1.5rem;
    animation: spinning_loading 0.7s infinite linear;
  }
}

@keyframes spinning_loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.main_video {
  position: relative;
  z-index: 1;
  padding-bottom: 7rem;
  padding-top: 5rem;

  &-title {
    text-align: center;

    h1 {
      background: -webkit-linear-gradient(
        top,
        rgb(255, 255, 255),
        rgb(170, 170, 170)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      filter: drop-shadow(0 0.5rem 0.5rem rgba(0, 0, 0, 0.4));
      font-weight: normal;
      font-size: 3.4rem;
    }
  }

  &-select {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.7rem;
    margin-bottom: 2.4rem;

    span {
      display: flex;
      padding: 0.6rem 2rem;
      color: var(--secondary_yellow);
      border-radius: 0.35rem;
      font-weight: 500;
      font-size: 0.95rem;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        color: var(--main_accent);
      }

      &.active {
        color: var(--main_accent);
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &-list {
    flex-direction: column;

    ul {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 3.9rem;

      li {
        list-style: none;
        width: 23.2rem;
        height: 17rem;
        box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.15);
        border-radius: 2.4rem;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          border: 2rem solid transparent;
          border-image: url(./assets/img/frames/frame_blue-shaded.png);
          border-image-slice: 35 35 fill;
          left: -0.3rem;
          right: -0.3rem;
          bottom: -0.3rem;
          top: -0.3rem;
          z-index: 1;
          pointer-events: none;
        }

        a {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: flex-end;
          text-decoration: none;

          &:hover {
            div:nth-child(1) {
              img {
                opacity: 1;
                transform: scale(1.1);
              }
            }

            div:nth-child(2) {
              span {
                svg {
                  opacity: 1;
                }
              }
            }
          }

          div:nth-child(1) {
            position: absolute;
            background-color: black;
            border-radius: 2.4rem;
            width: 100%;
            height: 100%;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              transition: 0.3s;
              object-fit: cover;
              border-radius: 2.4rem;
              opacity: 0.7;
            }
          }

          div:nth-child(2) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 5rem;
            background-color: rgba(20, 20, 34, 0.908);
            position: relative;
            z-index: 1;
            border-radius: 0 0 2.4rem 2.4rem;
            box-sizing: border-box;
            padding: 0 2rem 1rem 2.5rem;

            span {
              width: 2.2rem;
              height: 2.2rem;
              background-color: rgba(255, 255, 255, 0.05);
              display: flex;
              border-radius: 100%;
              align-items: center;
              justify-content: center;

              svg {
                font-size: 0.85rem;
                position: relative;
                left: 0.1rem;
                opacity: 0.7;
                transition: 0.3s;

                path {
                  fill: white;
                }
              }
            }

            p {
              margin: 0;
              color: rgba(255, 255, 255, 0.874);
              font-weight: 500;
              font-size: 0.95rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              width: 85%;
            }
          }
        }
      }
    }
  }
}

// end Main Page Styles

// Global Styles

.hidden {
  display: none !important;
}

.no-results-message {
  display: none;
  width: 100%;
  color: var(--secondary_yellow);
  background-color: var(--global_red-soft);
  border-radius: 0.3rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
}

.no-result-message {
  width: 100%;
  color: var(--secondary_yellow);
  background-color: var(--global_red-soft);
  border-radius: 0.3rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
}

.global_showMore {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  margin-top: 3rem;

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: var(--secondary_yellow) !important;
    font-family: "Poppins";
    border: none;
    border-radius: 0.35rem !important;
    padding: 0.7rem 1.8rem !important;
    font-weight: 500 !important;
    font-size: 0.85rem !important;
    gap: 0.6rem !important;
    transition: 0.3s;
    cursor: pointer;
    width: auto !important;
    height: auto !important;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    svg {
      font-size: 0.7rem;
      top: 0.05rem;
      position: relative;

      path {
        fill: var(--secondary_yellow);
      }
    }
  }
}

.global_searchingInput {
  position: relative;
  width: 20rem;
  height: 3rem;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: black;
    border: 0.05rem solid var(--global_blue-soft);
    border-radius: 0.3rem;
    padding: 0 3.5rem;
    font-weight: 500;
    color: var(--secondary_yellow);
    font-family: "Poppins";
    outline: none;
    transition: 0.3s;
    font-size: 0.9rem;

    &:focus {
      border-color: var(--main_accent);
    }

    &:focus ~ svg path {
      fill: var(--main_accent);
    }
  }

  #global_searchingInput-loading {
    position: absolute;
    width: 2rem;
    height: 2rem;
    display: flex;
    right: 1rem;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    border-left: 0.12rem solid rgba(255, 255, 255, 0.4);
    border-top: 0.12rem solid rgba(255, 255, 255, 0.4);
    animation: spinning_loading 0.5s infinite linear;
  }

  svg {
    position: absolute;
    left: 1.5rem;

    path {
      fill: var(--secondary_yellow);
      transition: 0.3s;
    }
  }
}

.global_message-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(1.1);
  pointer-events: none;
  transition: 0.3s;

  &.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }

  &_succesfully {
    div {
      background-color: var(--global_green-dark);

      p {
        strong {
          color: var(--global_green-warning);
        }
      }

      button {
        background-color: var(--global_green-soft);
        color: var(--global_green-warning);
      }
    }
  }

  &_error {
    div {
      background-color: var(--global_red-dark);

      p {
        strong {
          color: var(--global_red-warning);
        }
      }

      button {
        background-color: var(--global_red-soft);
        color: var(--global_red-warning);
      }
    }
  }

  div {
    width: 80%;
    max-width: 20rem;
    padding: 3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 2.4rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.67);

    &::after {
      content: "";
      position: absolute;
      border: 1rem solid transparent;
      border-image: url(./assets/img/frames/global_frame.png);
      border-image-slice: 35 35 fill;
      left: -0.2rem;
      right: -0.2rem;
      bottom: -0.3rem;
      top: -0.2rem;
      pointer-events: none;
    }

    p {
      color: var(--secondary_yellow);
      margin: 0;
      margin-bottom: 1rem;

      strong {
        font-weight: 600;
      }
    }

    button {
      width: 9rem;
      height: 2.6rem;
      font-weight: 500;
      cursor: pointer;
      font-family: "Poppins";
      border-radius: 0.2rem;
      font-size: 0.9rem;
      border: none;
      transition: 0.3s;

      &:hover {
        color: white;
      }

      &::before {
        content: "";
        position: fixed;
        z-index: -1;
        background-color: black;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        opacity: 0.6;
      }
    }
  }
}

.global_page-navIndicator {
  display: flex;
  margin-bottom: 3rem;
  padding: 0;

  li:not(:last-child) {
    &::before {
      content: "";
      position: absolute;
      right: 2rem;
      width: 0.1rem;
      opacity: 0.5;
      height: 80%;
      transform: rotate(15deg);
      background-color: var(--secondary_yellow);
    }
  }

  li {
    position: relative;
    list-style: none;
    display: flex;
    align-items: center;
    padding-right: 4rem;

    &.active {
      a {
        color: var(--main_accent);
      }
    }

    a {
      font-weight: 700;
      text-transform: uppercase;
      color: var(--secondary_yellow);
      text-decoration: none;
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      letter-spacing: 0.05rem;
      transition: 0.3s;

      &:hover {
        color: var(--main_accent);
      }

      svg {
        position: relative;
        top: -0.05rem;
        font-size: 1rem;

        path {
          fill: var(--secondary_yellow);
        }
      }
    }
  }
}

.global_centered-form {
  position: relative;
  z-index: 1;
  padding: 10rem 0;
  box-sizing: border-box;

  .main_container {
    display: flex;
    justify-content: center;
  }
}

.global_form {
  width: 95%;
  max-width: 40.2rem;
  background-color: var(--form);
  position: relative;
  border-radius: 2.8rem;
  padding: 5rem;
  box-sizing: border-box;

  &-heading {
    text-align: center;

    img {
      width: 5rem;
      margin-bottom: 1.5rem;
    }

    h1 {
      margin-bottom: 1.8rem !important;
    }

    p {
      color: var(--secondary_yellow);
      font-weight: 500;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 2.5rem;
    }
  }

  &-message {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
    color: var(--secondary_yellow);
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 2.3rem;

    a {
      padding: 0.35rem 1.7rem;
      border-radius: 0.25rem;
      border: 0.05rem solid var(--button_main);
      color: var(--main_accent);
      text-decoration: none;
      background-color: rgba(255, 255, 255, 0.05);
      transition: 0.3s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
        border-color: var(--main_accent);
      }
    }
  }

  button {
    font-family: "Strong Sword";
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  &-checkbox {
    padding-top: 0.5rem;
    margin-bottom: 2.3rem;

    label {
      display: flex;
      align-items: flex-start;
      gap: 1.2rem;

      input {
        display: none;
      }

      input:checked ~ span {
        background-color: var(--main_accent);

        svg {
          display: block;
        }
      }

      p {
        color: var(--secondary_yellow);
        margin: 0;
        width: 80%;
        font-size: 0.85rem;
        margin-top: 0.12rem;
        font-weight: 500;

        a {
          color: var(--main_accent);
          text-decoration: none;
          transition: 0.3s;

          &:hover {
            color: white;
          }
        }
      }

      span {
        display: flex;
        width: 1.2rem;
        font-size: 0.8rem;
        height: 1.2rem;
        background-color: var(--input);
        border: 0.1rem solid var(--input_border);
        align-items: center;
        justify-content: center;
        border-radius: 0.3rem;
        transition: 0.3s;
        cursor: pointer;

        svg {
          display: none;

          path {
            fill: black;
          }
        }
      }
    }
  }

  &-button {
    a {
      text-decoration: none;
    }

    button {
      border: none;
      background-color: var(--button_main);
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 0.3rem;
      height: 4.4rem;
      font-size: 1.1rem;
      letter-spacing: 0.05rem;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      overflow: hidden;
      transition: 0.3s;

      &:hover {
        box-shadow: inset 0 0 1rem var(--main_accent);

        &::before {
          left: -70%;
          opacity: 0.6;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 120%;
        height: 100%;
        background-color: var(--main_accent);
        left: -100%;
        top: 0;
        filter: blur(4rem);
        opacity: 0;
        transition: 0.5s;
      }
    }
  }

  &-input:not(:last-child) {
    margin-bottom: 2.3rem;
  }

  &-input {
    width: 100%;
    height: 3.85rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &_error {
      span.error {
        display: flex !important;
      }

      input {
        border-color: var(--global_red) !important;
      }

      svg {
        path {
          fill: var(--global_red) !important;
        }
      }
    }

    svg {
      position: absolute;
      left: 2rem;
      font-size: 1.2rem;
      z-index: 1;

      path {
        fill: var(--secondary_yellow);
      }
    }

    input {
      font-family: "Poppins";
      padding: 0 4.5rem;
      width: 100%;
      height: 100%;
      background-color: var(--input);
      border: 0.05rem solid var(--input_border);
      color: var(--secondary_yellow);
      font-weight: 600;
      font-size: 0.9rem;
      border-radius: 0.3rem;
      z-index: 1;
      outline: none;
      transition: 0.3s;
      position: relative;

      &:focus {
        border-color: var(--main_accent);
      }

      &:focus ~ svg path {
        fill: var(--main_accent);
      }
    }

    span {
      position: absolute;
    }

    span.error {
      right: 1.2rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 100%;
      background-color: var(--global_red);
      color: black;
      font-weight: 600;
      display: none;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    border: 2rem solid transparent;
    border-image: url(./assets/img/frames/global_frame.png);
    border-image-slice: 35 35 fill;
    left: -0.2rem;
    right: -0.2rem;
    bottom: -0.3rem;
    top: -0.2rem;
    pointer-events: none;
  }

  h1 {
    margin: 0;
    font-size: 2.2rem;
    text-align: center;
    color: var(--main_accent);
    margin-bottom: 3rem;
  }
}

.global_nav {
  position: sticky;
  top: 0;
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 4.5rem;

  &-mobile {
    display: none;
  }

  &-user {
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    padding: 0 2rem;
    height: 100%;
    gap: 1.2rem;

    &_cart {
      width: 2.3rem;
      height: 2.3rem;
      width: 2.3rem;
      height: 2.3rem;
      border-radius: 100%;
      border: 0.11rem solid rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        border-color: rgba(255, 255, 255, 0.2);

        &::before {
          transform: scale(1);
          opacity: 1;
        }
      }

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        transform: scale(0);
        opacity: 0;
        transition: 0.3s;
      }

      &-items {
        position: absolute;
        width: 0.8rem;
        right: 0;
        top: -0.2rem;
        height: 0.8rem;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--global_red);
        font-size: 0.7rem;
        color: var(--global_blue-darker);
        font-weight: 700;
      }

      svg {
        transform: scale(0.9);

        path {
          fill: white;
        }
      }
    }

    &_coin {
      display: flex;
      // width: 2rem;
      // height: 1.5rem;
      gap: 0.3rem;
      // background-color: rgba(255, 255, 255, 0.08);
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      text-decoration: none;
      color: white;
      transition: 0.3s;
      font-family: "Poppins";
      background-color: var(--global_blue-soft);
      border-radius: 3rem;
      padding: 0.45rem 0.45rem 0.45rem 0.8rem;
      justify-content: space-between;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }

      &:hover {
        color: var(--main_accent);
        background-color: rgba(255, 255, 255, 0.15);
      }

      div {
        margin-right: 1rem;
        color: var(--main_accent);
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        gap: 0.5rem;

        span {
          width: 0.9rem;
          height: 0.9rem;
          background-color: var(--main_accent);
          color: var(--global_blue-darker);
          border-radius: 100%;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.7rem;
        }
      }
    }

    &_lang {
      display: flex;
      align-items: center;
      padding-right: 1.45rem;
      width: 100%;
      height: 100%;
      min-width: 6rem;
      cursor: pointer;

      .coinsWrapper {
        padding: 0.25rem 0rem;
        width: 100%;
        border-radius: 0.25rem;
        border: 1px solid var(--main_accent);
        position: relative;
        transition: 300ms ease;
        background: transparent;

        .hoverDonate {
          position: absolute;
          opacity: 0;
          color: var(--custom_body);
          font-weight: bold;
          transition: 300ms ease;
          margin: 0 auto;
          text-align: center;
          width: 100%;
          top: 15%;
        }

        a {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 1rem;
          font-weight: bold;
          color: var(--main_accent);
          justify-content: center;
          transition: 300ms ease;
          text-decoration: none;

          svg {
            width: 1.85rem;
            height: 1.78rem;
          }
        }

        &:hover {
          background-color: var(--main_accent);

          a {
            opacity: 0;
          }

          .hoverDonate {
            opacity: 1;
          }
        }
      }

      // &.active {
      //   &::before {
      //     opacity: 1;
      //     pointer-events: all;
      //   }

      //   div:nth-child(2) {
      //     opacity: 1;
      //     top: 3.5rem;
      //     pointer-events: all;
      //   }

      //   div:nth-child(1) {
      //     border-color: rgba(255, 255, 255, 0.2);

      //     &::before {
      //       transform: scale(1);
      //       opacity: 1;
      //     }
      //   }
      // }

      &::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.748);
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;
        position: fixed;
        pointer-events: none;
        opacity: 0;
        transition: 0.3s;
      }

      // div:nth-child(2) {
      //   top: 2rem;
      //   right: 0;
      //   position: absolute;
      //   display: flex;
      //   background-color: rgb(40, 31, 24);
      //   border-radius: 2.4rem;
      //   box-sizing: border-box;
      //   padding: 2rem 4rem;
      //   transition: 0.4s;
      //   box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.305);
      //   gap: 4rem;
      //   opacity: 0;
      //   transition: 0.3s;
      //   pointer-events: none;

      // &::after {
      //   content: "";
      //   position: absolute;
      //   border: 1.5rem solid transparent;
      //   border-image: url(./assets/img/frames/frame_brown-shaded.png);
      //   border-image-slice: 35 35 fill;
      //   left: -0.2rem;
      //   right: -0.2rem;
      //   bottom: -0.2rem;
      //   top: -0.2rem;
      //   pointer-events: none;
      // }

      //   ul {
      //     box-sizing: border-box;
      //     padding: 0;

      //     li {
      //       list-style: none;

      //       span {
      //         color: var(--main_accent);
      //         margin-bottom: 0.5rem;
      //         display: flex;
      //       }

      //       a {
      //         color: var(--secondary_yellow);
      //         white-space: nowrap;
      //         text-decoration: none;
      //         transition: 0.3s;

      //         &:hover {
      //           color: white;
      //         }
      //       }
      //     }
      //   }
      // }

      // div:nth-child(1) {
      //   width: 2.3rem;
      //   height: 2.3rem;
      //   border-radius: 100%;
      //   border: 0.11rem solid rgba(255, 255, 255, 0.1);
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   font-size: 1rem;
      //   position: relative;
      //   cursor: pointer;
      //   transition: 0.3s;

      //   &:hover {
      //     border-color: rgba(255, 255, 255, 0.2);

      //     &::before {
      //       transform: scale(1);
      //       opacity: 1;
      //     }
      //   }

      //   &::before {
      //     content: "";
      //     width: 100%;
      //     height: 100%;
      //     position: absolute;
      //     border-radius: 100%;
      //     background-color: rgba(255, 255, 255, 0.2);
      //     transform: scale(0);
      //     opacity: 0;
      //     transition: 0.3s;
      //   }

      //   svg {
      //     position: relative;
      //     z-index: 1;

      //     path {
      //       fill: var(--main_accent);
      //     }
      //   }
      // }

      &::after {
        content: "";
        position: absolute;
        width: 0.1rem;
        height: 1.5rem;
        background-color: rgba(255, 255, 255, 0.5);
        right: 0rem;
        z-index: 2;
        opacity: 0.2;
      }
    }

    &_account {
      position: relative;
      display: flex;
      align-items: center;

      &-loggedIn {
        &_avatar {
          width: 2rem;
          height: 2rem;
          border-radius: 100%;
          border: 0.12rem solid var(--main_accent);
          overflow: hidden;
          margin-right: 0.2rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        div:nth-child(1) {
          p {
            text-transform: none;
            font-weight: 500;
          }
        }
      }

      &.active {
        div:nth-child(1) {
          color: var(--main_accent);

          .global_nav-dropdown_icon {
            transform: rotate(180deg);
          }
        }

        &::before {
          opacity: 1;
          pointer-events: all;
        }

        .global_nav-user_account-dropdown {
          top: 2.5rem;
          opacity: 1;
          pointer-events: all;
        }
      }

      &::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.748);
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;
        position: fixed;
        opacity: 0;
        pointer-events: none;
        transition: 0.3s;
      }

      .user_account-loggedIn {
        li {
          list-style: none;
          text-align: center;

          button {
            background-color: transparent;
            font-family: "Strong Sword";
            color: var(--global_red-warning);
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.8rem;
            font-size: 0.85rem;
            margin: 0 auto;
            margin-top: 0.8rem;
            cursor: pointer;
            transition: 0.3s;
            font-family: "Poppins";

            &:hover {
              color: white;
            }

            svg {
              path {
                fill: var(--global_red-warning);
              }
            }
          }

          span {
            width: 4.5rem;
            height: 4.5rem;
            border: 0.15rem solid var(--main_accent);
            border-radius: 100%;
            overflow: hidden;
            display: inline-flex;
            margin-bottom: 1rem;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          h1 {
            margin: 0;
            font-family: "Poppins";
            color: var(--main_accent);
            font-weight: 600;
            font-size: 1.2rem;
            letter-spacing: 0.05rem;
          }

          p {
            margin: 0;
            color: var(--secondary_yellow);
            margin-bottom: 1rem;
            font-size: 0.8rem;
          }

          a {
            width: 100%;
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            gap: 0.8rem;
            color: white;
            padding: 0.8rem;
            font-weight: 600;
            border-radius: 0.3rem;
            box-sizing: border-box;
            background-color: rgba(255, 255, 255, 0.05);
            transition: 0.3s;
            margin-bottom: 10px;

            &:hover {
              background-color: rgba(255, 255, 255, 0.1);
            }

            &:hover {
              svg {
                path {
                  fill: white;
                }
              }
            }

            svg {
              path {
                transition: 0.3s;
                fill: var(--main_accent);
              }
            }
          }
        }
      }

      .user_account-loggedOut {
        li {
          list-style: none;

          a {
            width: 100%;
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            gap: 0.8rem;
            color: white;
            padding: 0.8rem;
            font-weight: 600;
            border-radius: 0.3rem;
            box-sizing: border-box;

            &:hover {
              svg {
                path {
                  fill: white;
                }
              }
            }

            svg {
              path {
                transition: 0.3s;
                fill: var(--main_accent);
              }
            }
          }
        }

        li:nth-child(1) {
          a {
            background-color: rgba(255, 255, 255, 0.05);
            transition: 0.3s;

            &:hover {
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }

        li:nth-child(2) {
          margin-top: 1.5rem;
          border-top: 0.1rem solid rgba(255, 255, 255, 0.05);

          p {
            color: var(--secondary_yellow);
            text-align: center;
          }

          a {
            background-color: #17afcb44;
            transition: 0.3s;

            &:hover {
              background-color: #17afcb88;
            }
          }
        }
      }

      &-dropdown {
        width: 21rem;
        padding: 2.5rem;
        position: absolute;
        top: 0rem;
        background-color: rgb(40, 31, 24);
        border-radius: 2.4rem;
        box-sizing: border-box;
        transition: 0.3s;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.305);
        right: 0rem;
        z-index: 1;
        opacity: 0;
        pointer-events: none;

        &::after {
          content: "";
          position: absolute;
          border: 1.5rem solid transparent;
          border-image: url(./assets/img/frames/frame_brown-shaded.png);
          border-image-slice: 35 35 fill;
          left: -0.2rem;
          right: -0.2rem;
          bottom: -0.2rem;
          top: -0.2rem;
          pointer-events: none;
        }
      }

      div:nth-child(1) {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        font-size: 0.92rem;
        font-weight: 700;
        letter-spacing: 0.05rem;
        text-transform: uppercase;
        color: white;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          color: var(--main_accent);

          svg {
            path {
              fill: var(--main_accent);
            }
          }

          .global_nav-dropdown_icon {
            background-color: rgba(255, 255, 255, 0.15);
          }
        }

        svg {
          path {
            fill: var(--secondary_yellow);
            opacity: 0.5;
            transition: 0.3s;
          }
        }
      }
    }
  }

  &-dropdown_icon {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.06);
    display: flex !important;
    transition: 0.3s;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.75rem;
    box-sizing: border-box;

    svg {
      top: 0 !important;

      path {
        fill: var(--secondary_yellow) !important;
        opacity: 1 !important;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--global_nav);
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: 0.3s;
  }

  &.scrolled {
    &::before {
      opacity: 1;
      border-bottom: 0.1rem solid rgba(255, 255, 255, 0.07);
    }
  }

  &-items {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 0 1rem;

    &_dropdown {
      position: relative;
      display: flex;
      justify-content: center;

      &.active {
        a {
          color: var(--main_accent);
        }

        &::before {
          opacity: 1;
          pointer-events: all;
        }

        ul {
          top: 3.5rem;
          opacity: 1;
          pointer-events: all;
        }

        .global_nav-dropdown_icon {
          transform: rotate(180deg);
        }
      }

      &::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.748);
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;
        position: fixed;
        pointer-events: none;
        opacity: 0;
        transition: 0.3s;
      }

      ul {
        position: absolute;
        align-items: center;
        top: 2rem;
        background-color: rgb(40, 31, 24);
        border-radius: 2.4rem;
        box-sizing: border-box;
        padding: 2rem;
        opacity: 0;
        transition: 0.4s;
        pointer-events: none;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.305);

        &::after {
          content: "";
          position: absolute;
          border: 1.5rem solid transparent;
          border-image: url(./assets/img/frames/frame_brown-shaded.png);
          border-image-slice: 35 35 fill;
          left: -0.2rem;
          right: -0.2rem;
          bottom: -0.2rem;
          top: -0.2rem;
          pointer-events: none;
        }

        li {
          margin: 0;

          a {
            display: flex !important;
            justify-content: flex-start !important;
            width: 100%;
            padding: 0.8rem 2rem;
            border-radius: 0.3rem;
            color: var(--secondary_yellow) !important;
            white-space: nowrap;

            svg {
              width: 35px;
            }

            &:hover {
              background-color: rgba(255, 255, 255, 0.05);
            }
          }
        }
      }
    }

    &_shop {
      a {
        color: white !important;
        gap: 0.6rem !important;

        &:hover {
          color: var(--main_accent) !important;

          span {
            background-color: var(--main_accent);
          }
        }

        span {
          width: 1.2rem;
          height: 1.2rem;
          font-size: 0.8rem;
          background-color: var(--global_red);
          border-radius: 100%;
          color: black;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s;
        }
      }
    }

    &_icon {
      display: flex;
      align-items: center;
      margin: 0 !important;
      padding: 0 0.5rem;

      span {
        display: flex;
        align-items: center;
      }

      img {
        width: 3rem;
      }
    }

    li {
      margin: 2.4rem;
      display: flex;
      align-items: center;
      list-style: none;
      color: var(--secondary_yellow);
      font-size: 0.95rem;
      font-weight: 700;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      user-select: none;

      a {
        text-decoration: none;
        color: var(--secondary_yellow);
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          color: var(--main_accent);

          .global_nav-dropdown_icon {
            background-color: rgba(255, 255, 255, 0.15);
          }
        }

        svg {
          position: relative;
          top: -0.05rem;

          path {
            fill: var(--main_accent);
          }
        }
      }
    }
  }
}

.un1k {
  width: 2.8rem;
  height: 2.8rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  &:hover {
    background-color: white;

    img {
      filter: invert(0);
      opacity: 1;
    }
  }

  img {
    filter: invert(100%);
    width: 1.5rem;
    opacity: 0.5;
    transition: 0.3s;
  }
}

.global_footer {
  background: url(./assets/img/bg/main/footer_bg.webp) top no-repeat;
  position: relative;
  z-index: 1;

  &-bottom {
    border-top: 0.1rem solid rgba(255, 255, 255, 0.05);
    width: 100%;
    padding: 2.5rem 0 6rem 0;

    .main_container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ul:nth-child(1) {
        li {
          a {
            padding: 0.7rem 1.8rem;
            color: var(--main_accent);
            text-decoration: none;
            display: flex;
            align-items: center;
            font-size: 0.85rem;
            position: relative;
            opacity: 0.7;
            background-color: rgba(255, 255, 255, 0.05);
            border-radius: 0.3rem;
            gap: 0.8rem;
            transition: 0.3s;

            &:hover {
              opacity: 1;
            }

            span {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              position: relative;
              z-index: 1;
            }

            svg {
              opacity: 0.5;
              font-size: 0.75rem;

              path {
                fill: var(--main_accent);
              }
            }
          }
        }
      }

      ul {
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        gap: 1.8rem;

        li {
          list-style: none;
        }
      }
    }
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 5rem 0 4rem 0;
    box-sizing: border-box;

    &_copyright {
      display: flex;
      align-items: center;
      gap: 2rem;

      div {
        display: flex;
        justify-content: center;
        flex-direction: column;

        img {
          width: 3.5rem;
        }

        p {
          font-size: 0.9rem;
          font-weight: 500;
          margin: 0;
        }

        p:nth-child(1) {
          color: var(--secondary_yellow);
        }

        p:nth-child(2) {
          color: var(--secondary_yellow);
          opacity: 0.4;
        }
      }
    }

    &_social {
      display: flex;
      align-items: center;
      padding: 0;
      gap: 1.7rem;

      li:not(:last-child) {
        border-right: 0.12rem solid rgba(255, 255, 255, 0.1);
        padding-right: 1.7rem;
      }

      li {
        list-style: none;

        a {
          font-size: 1.4rem;
          display: flex;
          align-items: center;

          &:hover {
            svg {
              path {
                fill: var(--main_accent);
              }
            }
          }

          svg {
            path {
              transition: 0.3s;
              fill: var(--secondary_yellow);
            }
          }
        }
      }
    }

    &::before {
      content: "";
      background: url(./assets/img/frames/frame_line.png) top / cover no-repeat;
      width: 100%;
      height: 1.29rem;
      opacity: 0.5;
      top: 0;
      left: 0;
      position: absolute;
    }
  }
}

// end Global Styles

// Media

@media (max-width: 1580px) {
  .global_nav {
    &-user {
      &_account-loggedIn {
        div {
          p {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1370px) {
  .main_video {
    &-list {
      ul {
        justify-content: center;
      }
    }
  }

  .main_features {
    &-title {
      width: 100%;
      text-align: center;
      margin-bottom: 6rem;
    }
  }

  .main_bg {
    position: absolute;
  }

  .main_history {
    &::before {
      display: none;
    }

    &-content {
      &_wrapper {
        position: relative;
        top: 0;
      }
    }
  }

  .global_nav {
    &-mobile {
      width: 2.3rem;
      height: 2.3rem;
      border-radius: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      position: relative;
      z-index: 2;
      margin: 0 1rem;

      &::before {
        content: "";
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.7);
        top: 0;
        left: 0;
        transition: 0.3s;
        pointer-events: none;
        opacity: 0;
      }

      svg {
        path {
          fill: var(--main_accent);
        }
      }

      svg:nth-child(2) {
        display: none;
      }

      &.active {
        background-color: rgba(255, 255, 255, 0.2);

        &::before {
          opacity: 1;
          pointer-events: all;
        }

        svg:nth-child(1) {
          display: none;
        }

        svg:nth-child(2) {
          display: block;
        }
      }
    }

    &-user {
      padding: 0 1rem;

      &_lang {
        div:nth-child(2) {
          flex-direction: column;
          gap: 1rem;
          right: -4rem;
          width: 15rem;
          padding: 2rem 3rem;
        }
      }
    }

    &-items {
      position: fixed;
      z-index: 2;
      top: 3.5rem;
      padding: 0;
      box-sizing: border-box;
      left: -20rem;
      opacity: 0;
      height: 100vh;
      overflow-y: auto;
      background: var(--global_nav);
      flex-direction: column;
      width: 20rem;
      transition: 0.3s;

      &.active {
        left: 0;
        opacity: 1;
      }

      &_icon {
        border: none !important;
        display: none !important;
      }

      &_dropdown {
        position: relative;
        flex-direction: column;

        a {
          width: 100%;
          justify-content: space-between;
          gap: 0;
          box-sizing: border-box;
        }

        &::before {
          display: none;
        }

        &.active {
          ul {
            top: 0;
            max-height: 20rem;
          }
        }

        ul {
          position: relative;
          top: 0;
          max-height: 0;
          transition: 0.8s;
          padding: 0;
          box-shadow: none;
          background-color: rgb(28, 25, 20);
          border-radius: 0;
          overflow: hidden;
          width: 100% !important;

          &::after {
            display: none;
          }

          li {
            padding: 0;
            width: 100% !important;
            margin: 0;

            a {
              width: 100%;
              padding: 1.8rem 2.5rem;
              justify-content: start !important;
            }
          }
        }
      }

      li {
        box-sizing: border-box;
        margin: 0;
        width: 100%;

        border-top: 0.1rem solid rgba(255, 255, 255, 0.03);

        a {
          padding: 1.8rem 2.4rem;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .news_read {
    &-content {
      &_topic {
        width: 100%;
      }

      &_menu {
        display: none;
      }
    }
  }

  .shop {
    overflow: hidden;

    &_special {
      .shop_section-title {
        justify-content: start !important;
      }

      &-slider {
        overflow: visible;

        &_pagination {
          display: none;
        }

        &_prev {
          display: none;
        }

        &_next {
          display: none;
        }
      }
    }

    &_menu {
      justify-content: center;

      div {
        gap: 0.5rem !important;
      }

      p {
        margin: 0 !important;
      }

      .global_searchingInput {
        width: 100%;
      }
    }

    &_section {
      justify-content: center;

      &-title {
        justify-content: center !important;
      }

      ul {
        justify-content: center;
      }
    }
  }

  .global_footer {
    background-size: cover;
  }

  .main_history {
    &-content {
      &_wrapper {
        div:nth-child(1) {
          height: 18rem;
        }
      }
    }
  }

  .dashboard {
    &_menu-mobile {
      display: flex;
      width: 3rem;
      height: 3rem;
      background-color: var(--dashboard_blue-soft);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0.5rem;
      font-size: 1.2rem;
      border-radius: 0.3rem;
      z-index: 2;

      svg {
        path {
          fill: var(--main_accent);
        }
      }
    }

    &_menu {
      position: fixed;
      top: 5rem;
      right: 1rem;
      width: 18rem;
      height: 100vh;
      z-index: 9;
      opacity: 0;
      right: -20rem;
      transition: 0.5s;
      backdrop-filter: blur(2rem);
      padding-top: 6rem;
      pointer-events: none;

      &.active {
        right: 1rem;
        opacity: 1;
        pointer-events: all;
      }

      li {
        a {
          svg {
            display: none;
          }
        }
      }
    }

    &_content {
      width: 100%;
    }
  }

  .global_form {
    padding: 3rem;
    width: 100%;
  }

  .sign-up {
    padding: 5rem 0;

    .main_container {
      width: 98%;
      justify-content: center;
    }
  }

  .global_centered-form {
    padding: 5rem 0;

    .main_container {
      width: 98%;
    }
  }

  .global_footer {
    &-top {
      flex-direction: column;

      div:nth-child(1) {
        flex-direction: column;
        text-align: center;
      }
    }

    &-bottom {
      .main_container {
        flex-direction: column;

        ul {
          flex-direction: column;
        }
      }
    }
  }

  .main_header {
    &-link {
      span:nth-child(3) {
        &::after {
          display: none;
        }

        &::before {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .shop_cart {
    &-summary {
      width: 100%;
    }

    .main_container {
      gap: 2rem;
    }

    &-items {
      width: 100%;

      &_info {
        gap: 1.5rem !important;

        &-product {
          width: 100%;
        }

        &-delete {
          position: relative !important;
        }
      }

      &_table {
        div:nth-child(2) {
          display: none;
        }

        div:nth-child(3) {
          display: none;
        }
      }
    }
  }

  .dashboard {
    padding: 5rem 0;

    &_content {
      &-characters {
        li {
          height: auto;

          div:nth-child(2) {
            padding: 2rem;
          }

          div:nth-child(3) {
            padding: 2rem;
          }
        }
      }

      .dashboard_orderHistory {
        &-details {
          gap: 2rem;
        }
      }

      .dashboard_ipConnect {
        padding-top: 2.5rem;

        table {
          tr {
            width: 100%;

            td {
              width: auto;
            }
          }
        }

        &-message {
          right: 0;
          top: -0.8rem;
        }
      }
    }
  }

  .dusk_coin {
    &-list {
      label {
        width: 100%;
      }
    }

    &-summary {
      ul {
        flex-wrap: wrap;
        gap: 2rem;
        padding: 2rem;

        li {
          width: 40%;

          button {
            height: 3rem;
          }
        }

        li:last-child {
          width: 100%;
        }
      }
    }
  }

  .customs {
    .bg_cover {
      &::before {
        background-size: cover !important;
        height: 40rem;
      }
    }

    &_content-withList {
      ul {
        justify-content: center;
      }

      .customs_content-title {
        margin-bottom: 10rem;
      }
    }

    &_content-withTab {
      .bg_cover {
        &::before {
          background-size: cover !important;
          height: 40rem;
        }
      }

      &_content {
        min-height: 40rem;

        .main_container {
          div {
            width: 100%;
          }
        }

        &-table {
          h1 {
            width: 100%;
          }

          ul {
            overflow-x: scroll;

            li {
              width: fit-content;
              gap: 2rem;

              div {
                img {
                  width: 10rem;
                }

                span {
                  width: 10rem;
                }
              }
            }
          }
        }

        .newRace_img {
          display: none;
        }
      }

      &_tabs {
        .main_container {
          overflow-x: scroll;
          width: 100%;
          justify-content: start;
          padding: 0 4rem;
          box-sizing: border-box;
        }

        span {
          display: flex;
          width: 20rem;
          white-space: nowrap;
        }
      }
    }

    &_content {
      &-title {
        h1 {
          font-size: 2.4rem;
        }
      }
    }

    &_heading {
      h1 {
        width: 100% !important;
        line-height: 4rem;
      }

      p {
        width: 100% !important;
      }

      &-slider {
        &_prev {
          display: none;
        }

        &_next {
          display: none;
        }
      }
    }
  }

  .news_read {
    padding-top: 3rem;

    &-title {
      h1 {
        font-size: 2rem;
        line-height: 2.8rem;
      }
    }

    &-content {
      &_topic {
        width: 100%;

        img {
          max-width: 100% !important;
        }

        ul {
          padding: 1rem 2rem;
        }
      }

      &_menu {
        display: none;
      }
    }
  }

  .news {
    .main_container {
      width: 80%;
    }

    &_menu {
      .global_searchingInput {
        width: 100%;
      }
    }
  }

  .changelog {
    .main_container {
      width: 85%;
    }
  }

  .shop_view {
    &-item_description {
      width: 100%;
      margin-bottom: 3rem;
      position: relative;
      top: 0;

      &-img {
        height: 20rem;
      }
    }

    &-item_info {
      width: 100%;

      &-name {
        text-align: center;
      }
    }
  }

  .shop {
    &_section {
      ul {
        li {
          width: 100%;
        }
      }
    }
  }

  .main_container {
    width: 60%;
  }

  .main_history {
    &-content {
      gap: 5rem;
      flex-wrap: wrap;

      &_wrapper {
        width: 100%;
      }
    }
  }

  .main_header {
    height: 50rem;

    &-link {
      a {
        width: 15rem;

        span:nth-child(1) {
          font-size: 0.8rem;

          svg {
            font-size: 0.7rem;
          }
        }

        span:nth-child(2) {
          img {
            width: 20rem;
          }
        }

        span:nth-child(3) {
          img {
            width: 19rem;
          }
        }

        span:nth-child(4) {
          img {
            width: 18.5rem;
          }
        }
      }
    }

    &-logo {
      img {
        width: 22rem;
      }
    }

    &-slogan {
      margin-bottom: 3rem;

      h1 {
        font-size: 2.5rem;
        line-height: 3rem;
      }
    }
  }
}

@media (max-width: 480px) {
  .news {
    &_list {
      &-news {
        ul {
          li {
            a {
              flex-direction: column;
              gap: 1rem;

              div {
                width: 100% !important;
              }

              div:nth-child(2) {
                gap: 2rem;
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .changelog {
    &_menu {
      .main_container {
        flex-direction: column-reverse;
      }
    }

    &_logs {
      ul {
        padding-left: 0;

        &::before {
          display: none;
        }

        li {
          &::before {
            display: none;
          }
        }
      }

      &-date {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }

      &-list {
        padding: 2rem 0;
      }
    }
  }

  .shop_view {
    &-item_description {
      &-img {
        height: 15rem;
      }
    }
  }

  .main_container {
    width: 80%;
  }

  .dashboard {
    .main_container {
      width: 90%;
    }

    &_content {
      &-withList_title {
        text-align: center;
      }

      &-withList_list {
        li {
          div:nth-child(1) {
            height: auto;
            padding: 1.5rem;
            gap: 1rem;
            flex-direction: column;

            span:nth-child(1) {
              color: var(--primary_blue);
            }
          }
        }
      }

      &-profile {
        &_avatarProfile {
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 100%;
        }

        &_avatarButton {
          margin: 0 auto;
        }
      }
    }
  }

  .main_features {
    padding-top: 8rem;

    &-title {
      h1 {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
  }

  .main_history {
    &-title {
      h1 {
        font-size: 2.2rem;
      }
    }

    &-content {
      &_wrapper {
        div:nth-child(1) {
          height: 17rem;
        }
      }
    }
  }
}

@media (min-width: 1921px) {
  .customs {
    .bg_cover {
      &::before {
        background-size: cover;
      }
    }
  }

  .page_bg {
    background-size: cover;
  }

  .global_footer {
    background-size: cover;
  }

  .main_bg {
    &-effect {
      &::before {
        background-size: cover;
      }

      &::after {
        background-size: cover;
      }
    }

    &::before {
      background-size: cover;
    }

    &::after {
      background-size: cover;
    }
  }

  .main_end-wrapper {
    &::before {
      background-size: cover;
    }
  }

  .main_history {
    &::before {
      background-size: cover;
    }
  }
}

// Font Fase

html {
  font-size: var(--base-font-size);
}

// Font Base Media

@media (max-width: 480px) {
  :root {
    --base-font-size: 12px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  :root {
    --base-font-size: 12px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  :root {
    --base-font-size: 14px;
  }
}

@media (min-width: 1025px) and (max-width: 1365px) {
  :root {
    --base-font-size: 14px;
  }
}

@media (min-width: 1366px) and (max-width: 1920px) {
  :root {
    --base-font-size: 14px;
  }
}

@media (min-width: 1921px) {
  :root {
    --base-font-size: 16px;
  }
}

// Fancybox

.fancybox-bg {
  background: rgb(12, 11, 8) !important;
}

.compensate-for-scrollbar {
  margin-right: 0 !important;
}
