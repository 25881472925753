$btncolor: #c07f00;
$goldtext: #ffd95a;
$cardbg: #14120d;
$hovercolor: #ffd95a;
$btnhover: #4c3d3d;


//PRIO
$gamebreaking: #f00;
$high: #bd818e;
$medium:#46ba6c;
$low: #2e67ac;